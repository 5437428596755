import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import firebase from 'firebase';

import OnSnapshotController from '../controllers/OnSnapshotController';
import dokiboMapIcon from '../dokibo-map-icon.png';
import ChalkboardIcon from '../icons/ChalkboardIcon';

/* global FS */

const styles = theme => ({
  main: {},
  grow: {
    flexGrow: 1,
  },
  logo: {
    height: '36px',
    width: '36px',
    marginRight: theme.spacing(1),
  },
  linkDiv: {
    /* https://stackoverflow.com/questions/28362688/css-display-inline-flex-and-text-overflow-ellipsis-not-working-togethier */
    display: 'inline-flex',
  },
  linkCollapser: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '50px',
    },
  },
  link: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  unstyledLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    }
  },
  historyUserThumb: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '250',
    [theme.breakpoints.down('sm')]: {
      width: '150',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80px',
    },
    marginTop: 0,
    marginBottom: 0,
    flexWrap: ''
  },
  menu: {
    width: 288,
  },
});

class dokiboHeader extends PureComponent {
  state = {
    userMenuOpen: false,
  }

  constructor(props) {
    super(props);
    this.userMenuAnchorRef = React.createRef();
  }

  logOut = () => {
    firebase.auth().signOut();
    FS.identify(false);
  }

  handleClickUserPhoto = (event) => {
    this.setState((prevState, props) => {
      return {
        userMenuOpen: !prevState.userMenuOpen,
      }
    })
  }

  handleCloseUserMenu = (event) => {
    console.log('handleCloseUserMenu');

    /* prevent menu from closing immediately */
    if (this.userMenuAnchorRef.current && this.userMenuAnchorRef.current.contains(event.target)) {
      return;
    }

    this.setState({
      userMenuOpen: false,
    })
  }

  render() {
    let classes = this.props.classes;

    return (
      <div className={classes.main}>
        <Helmet title={this.props.title} />
        <AppBar color='default' position="fixed">
          <Toolbar>
            <a href='/'>
              <img className={classes.logo} src={dokiboMapIcon} />
            </a>
            <Breadcrumbs aria-label="Breadcrumb" className={classes.grow}>
              {this.props.breadcrumbs.map(breadcrumb =>
                (breadcrumb.href === undefined) ?
                  <Typography key={breadcrumb}>
                    {breadcrumb.title}
                  </Typography>
                  :
                  <div className={classes.linkDiv} key={breadcrumb.href}>
                    <Link className={classes.link} color="inherit" href={breadcrumb.href} >
                      {breadcrumb.title}
                    </Link>
                  </div>
              )}

            </Breadcrumbs>
            {firebase.auth().currentUser &&
            <div>
              <img
                aria-controls="simple-menu"
                aria-haspopup="true"
                ref={this.userMenuAnchorRef}
                onClick={this.handleClickUserPhoto}
                className={classes.historyUserThumb}
                src={firebase.auth().currentUser.photoURL} />
            </div>
            }
            {false && <ChalkboardIcon />}
            {!firebase.auth().currentUser && !this.props.hideSignInButton &&
              <Button component={RouterLink} to="/signin?next=/conversations" color="inherit">Sign in</Button>
            }
            <Popper open={this.state.userMenuOpen} anchorEl={this.userMenuAnchorRef.current} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom' }}
                >
                  <Paper id="menu-list-grow">
                    <OnSnapshotController
                      key={firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).path}
                      dbRef={firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid)}>
                      { data =>
                        <ClickAwayListener onClickAway={this.handleCloseUserMenu}>
                          <MenuList>
                            <Link className={classes.unstyledLink} color="inherit" href={'/conversations'} >
                              <MenuItem>
                                Conversations
                              </MenuItem>
                            </Link>
                            {data && data.snapshot && (data.snapshot.get('isPartner') === true) &&
                                <Link className={classes.unstyledLink} color="inherit" href={'/partners'} >
                                  <MenuItem>
                                    Partner Conversations
                                  </MenuItem>
                                </Link>
                              }
                            <MenuItem>Signed in as {firebase.auth().currentUser.displayName}</MenuItem>
                            <MenuItem onClick={this.logOut}>Logout</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      }
                    </OnSnapshotController>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

dokiboHeader.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  hideSignInButton: PropTypes.bool,
};

export default withStyles(styles)(dokiboHeader);
