// rootMeanSquare :: [Num] -> Real
const rootMeanSquare = xs =>
   Math.sqrt(
        xs.reduce(
            (a, x) => (a + x * x),
            0
       ) / xs.length
    );

export default rootMeanSquare;
