import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import {red} from '@material-ui/core/colors'

import firebase from 'firebase';

import RecordBar from '../views/RecordBar';
import OnSnapshotController from '../controllers/OnSnapshotController';
import AsyncStorageData from '../controllers/AsyncStorageData';
import WzRecorder from '../WzRecorder';
import DokiboHeader from '../views/DokiboHeader';

import util from 'util';

const RedButton = withStyles(theme => ({
  root: {
    color: red[500],
  },
}))(Button);

const styles = theme => ({
  container: {
    paddingTop: 'calc(64px)', // header is 64px
  },
  addMessageDialog: {
    marginTop: theme.spacing(2),
    paddingBottom: '87px', // 79 for RecordBar + 8 for margin.
    overflow: 'scroll',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',

  },
  audioActions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  audio: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  errorDiv: {
    border: `1px solid ${red[500]}`,
    background: red[100],
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: 'fit-content',
  }
});

class AddAudioPage extends Component {
  state = {
  }

  constructor(props) {
    super(props);

    this.recorder = new WzRecorder({
      onRecordingStop: this.handleRecordingStop,
      onRecording: this.handleRecordingTick,
    });
  }

  handleClickRecord = () => {
    this.recorder.toggleRecording();
    console.log('toggle recording');
  }

  handleRecordingTick = (milliseconds) => {
    this.setState({
      currentRecordingDuration: milliseconds,
    })
  }

  handleRecordingStop = (blob) => {
    console.log('handleRecordingStop')

    this.setState({
      draftAudio: {
        data: blob,
        url: URL.createObjectURL(blob),
        duration: this.state.currentRecordingDuration,
      },
      currentRecordingDuration: undefined,
    })
  }

  handleDeleteDraft = () => {
    this.setState({
      draftAudio: undefined,
    })
  }

  handleSubmit = () => {
    let storageRef = firebase.storage().ref()
      .child('recordings').child(this.messageSnapshot.id);

    this.setState({
      uploading: true,
    })

    storageRef.put(this.state.draftAudio.data)
    .then(snapshot => this.messageSnapshot.ref.set({
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      audioPath: storageRef.fullPath,
      audioState: 'uploaded',
      durationMillis: this.state.draftAudio.duration,
      audioUploadError: firebase.firestore.FieldValue.delete(),
    }, {merge: true}))
    .then(snapshot => {
      console.log(`finished uploading audio for message`, this.messageSnapshot.id);
      this.setState({
        draftAudio: undefined,
        uploading: undefined,
      })
    })
    .catch(error => {
      console.log(error);

      this.setState({
        errorMessage: `Error, please send this message code to Andrew: ${this.messageSnapshot.id}`,
      });

      this.messageSnapshot.ref.set({
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
        audioUploadError: util.inspect(error),
      }, {merge: true})
    });
  }

  renderMessagessSnapshot = (snapshot) => {
    const classes = this.props.classes;

    if (snapshot === undefined || snapshot.docs === undefined) {
      return null;
    } else if (snapshot.docs.length === 0) {
      return <div className={classes.addMessageDialog}>
        <Typography variant='h6'>No messages to record at the moment.</Typography>
      </div>
    }

    this.messageSnapshot = snapshot.docs[0];

    return (
      <div className={classes.addMessageDialog}>
        <Typography variant='body1'>{snapshot.docs.length} messages left to record</Typography>
        <Typography variant='body1'>Record yourself saying this message:</Typography>
        <Typography variant='body2'>{this.messageSnapshot.get('text')}</Typography>
        {this.messageSnapshot.get('speaker') &&
          <Typography variant='body2'>As the speaker: {this.messageSnapshot.get('speaker')}</Typography>
        }
        {this.state.draftAudio &&
          <React.Fragment>
            <audio className={classes.audio} controls src={this.state.draftAudio.url} />
            <div className={classes.audioActions}>
              {!this.state.uploading &&
                <React.Fragment>
                  <RedButton className={classes.button} onClick={this.handleDeleteDraft}>delete draft</RedButton>
                  <Button variant='contained' color='primary' onClick={this.handleSubmit}>submit</Button>
                </React.Fragment>
              }
            </div>
            {this.state.uploading &&
              <div>
                <LinearProgress color='secondary' />
                <Typography variant='body1'>uploading...</Typography>
              </div>
            }
          </React.Fragment>
        }
        {this.state.errorMessage &&
          <div className={classes.errorDiv}>{this.state.errorMessage}</div>
        }
      </div>
    )
  }


  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.conversation}>
        <DokiboHeader
          title='Dokibo | Add Audio'
          breadcrumbs={[
            {title: 'Dokibo', href: `/`},
            {title: 'Add Audio Recordings'}
          ]}

        />
        <div className={classes.container}>
          <OnSnapshotController
            key={firebase.firestore().collection('needs-audio').path}
            dbRef={firebase.firestore().collection('needs-audio')
                   .where('voicedBy', '==', firebase.auth().currentUser.uid)
                   .where('audioPath', '==', null)}
            >
            {data => data &&
              this.renderMessagessSnapshot(data.snapshot)
            }
          </OnSnapshotController>
          <RecordBar
            recorder={this.recorder}
            onClickRecord={this.handleClickRecord}
            isRecording={this.state.currentRecordingDuration !== undefined}
            message={<span>Tap to<br />record</span>}
            disabled={this.state.draftAudio !== undefined}
             />
        </div>
      </div>
    );
  }
}


AddAudioPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddAudioPage);
