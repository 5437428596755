import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { red, green } from '@material-ui/core/colors';
import Modal from '@material-ui/core/Modal';

import firebase from 'firebase';
import moment from 'moment';

import OnSnapshotController from '../controllers/OnSnapshotController';
import AudioPlayer from '../components/AudioPlayer';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

var currentlyPlayingAudioRef = null;

const RedButton = withStyles(theme => ({
  root: {
    color: red[500],
  },
}))(Button);

const styles = theme => ({
  messageRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  messageRowReverse: {
    flexDirection: 'row-reverse !important',
  },
  profilePhoto: {
    width: '48px',
    height: '48px',
    backgroundColor: theme.palette.grey[100],
    borderRadius: '50%',
    marginTop: '3px',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  messageCol: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  context: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  button: {
    width: 'fit-content',
    fontSize: '75%',
  },
  quizContainer: {
    marginTop: theme.spacing(1),
  },
  transcript: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: 'x-large',
  },
  correctText: {
    backgroundColor: green[50],
    borderColor: green[900],
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  incorrectText: {
    backgroundColor: red[50],
    borderColor: red[900],
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
});

export default withStyles(styles)(function SituationOtherSpeakerMessage(props) {
  const [showTranscript, setShowTranscript] = React.useState(false);
  const [showQuiz, setShowQuiz] = React.useState(false);
  const [quizGuess, setQuizGuess] = React.useState(-1);

  let classes = props.classes;
  console.log('otherUser', props.snapshot, props.snapshot.get('user'));
  let otherUser = props.snapshot && props.snapshot.get('user');
  let sentBySelf = props.snapshot.get('userId') === firebase.auth().currentUser.uid;
  let userRef = firebase.firestore().collection('characters').doc(props.snapshot.get('characterId'));

  let userName = otherUser ? otherUser.name : '';

  let onClickTranscript = () => {
    console.log('onClickTranscript')
    setShowTranscript(!showTranscript);
  }

  let handlePlay = (event) => {
    if (currentlyPlayingAudioRef !== null) {
      currentlyPlayingAudioRef.pause();
    }

    currentlyPlayingAudioRef = event.target;
  }

  let handlePause = (event) => {
    if (currentlyPlayingAudioRef === event.target) {
      currentlyPlayingAudioRef = null;
    }
  }


  let handleEnded = () => {
    setShowQuiz(true);
    if (props.snapshot.get('quiz') === undefined) {
      props.onPlaybackEnded && props.onPlaybackEnded(props.snapshot);
    }
  }

  let handleQuizCompleted = (event) => {
    setQuizGuess(parseInt(event.target.value));
    props.onPlaybackEnded && props.onPlaybackEnded(props.snapshot);
  }


  console.log('in message thisAudioRef', props.audioRef);
  console.log('autoplay', props.autoplay)

  let correctAnswer = props.snapshot.get('quiz') &&
    props.snapshot.get('quiz').correctAnswer;

  return (
    <div>
      <div className={classes.context}>
        {props.snapshot.get('speaker')} says...
      </div>
      <OnSnapshotController
        key={userRef.path}
        dbRef={userRef}
        >
        {data => data && data.snapshot &&
          <div className={sentBySelf ? classes.messageRow : [classes.messageRow, classes.messageRowReverse].join(' ')}>
            <img className={classes.profilePhoto} src={data.snapshot.get('portraitUrl')} />
            <div className={classes.messageCol}>
              <AudioPlayer
                audioRef={props.audioRef}
                onPlay={handlePlay}
                onPause={handlePause}
                onEnded={handleEnded}
                className={classes.audio}
                src={props.audioSrc}
                defaultExpanded={props.defaultExpanded}
                autoplay={props.autoplay ? 'autoplay' : false}
                transcript={props.snapshot.get('text')}
                expandedContent={<React.Fragment>
                   {!sentBySelf && <Button onClick={onClickTranscript} className={classes.button} size="small">{showTranscript ? 'hide' : 'show'} transcript</Button>}
                   {showTranscript &&
                    <div className={classes.transcript}>
                     {props.snapshot.get('text') || 'no transcript'}
                    </div>}
                </React.Fragment>}
                />
            </div>
          </div>
          || null
        }
      </OnSnapshotController>
      {showQuiz && props.snapshot.get('quiz') &&
        <div className={classes.quizContainer}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">{props.snapshot.get('quiz').question}</FormLabel>
            <RadioGroup aria-label="quiz" name="quiz1" value={quizGuess} onChange={handleQuizCompleted}>
              {props.snapshot.get('quiz').responses.map((response, i) =>
                <FormControlLabel
                  value={i}
                  control={<Radio />}
                  label={<span>
                    {response}
                    {quizGuess === i && quizGuess === correctAnswer &&
                      <span className={classes.correctText}>Correct!</span>}
                    {quizGuess === i && quizGuess !== correctAnswer &&
                      <span className={classes.incorrectText}>Sorry, try again.</span>}
                  </span>}
                  />
              )}
            </RadioGroup>
          </FormControl>
        </div>
      }
    </div>
  );
});
