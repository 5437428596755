import elasticlunr from 'elasticlunr';
import searchDocs from '../searchDocs.json';

export default function() {
  if (global.dokiboIndex !== undefined) {
    return global.dokiboIndex;
  }

  let startTime = new Date();

  global.dokiboIndex = elasticlunr(function() {
    this.addField('definition')
    this.setRef('id')
  })

  for (var i=0; i < searchDocs.length; i++) {
    global.dokiboIndex.addDoc({id: i, ...searchDocs[i]});
  }

  let endTime = new Date();

  console.log(`built index in ${(endTime-startTime)}ms`);

  return global.dokiboIndex;
}
