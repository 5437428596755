import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import { red } from '@material-ui/core/colors';

import { isiOS, isiOSSafari } from '../helpers/platformQueries';

const styles = theme => ({
  bar: {
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    height: '78px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    borderTop: `1px solid ${theme.palette.grey[500]}`,
  },
  barRecording: {
    backgroundColor: red[100],
  },
  grow: {
    flexGrow: 1,
  },
  '@keyframes recording-pulse': {
    '0%': {
      color: theme.palette.getContrastText(red[900]),
      backgroundColor: red[900],
    },
    '100%': {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
    },
  },
  micButton: {
    color: theme.palette.getContrastText(red[100]),
    backgroundColor: red[100],
    '&:hover': {
      backgroundColor: red[200],
    },
  },
  micButtonRecording: {
    animationName: '$recording-pulse',
    animationDuration: '1.0s',
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'forwards',
    animationDirection: 'alternate',
    animationIterationCount: 'infinite',
  },
  '@keyframes unroll-rollup': {
    '0%': {
      clipPath: 'inset(0 0 0 100%)',
      ['-webkit-clipPath']: 'inset(0 0 0 100%)',
      left: '-100%',
    },
    '12%, 100%': {
      clipPath: 'inset(0 0 0 0)',
      ['-webkit-clipPath']: 'inset(0 0 0 0)',
      left: '0',
    },
  },
  notSafariExplainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.getContrastText(red[100]),
    backgroundColor: red[100],
    height: 'inherit',
  },
  micExplainerContainer: {
    position: 'absolute',
    left: '50%',
  },
  micExplainer: {
    clipPath: 'inset(0 0 0 100%)',
    ['-webkit-clipPath']: 'inset(0 0 0 100%)',
    left: '-100%',
    position: 'relative',
    paddingLeft: 56/2+6,
    paddingTop: '4px',
    paddingRight: '10px',
    paddingBottom: '4px',
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.getContrastText(theme.palette.grey[400]),
    borderRadius: theme.shape.borderRadius,
    animationName: '$unroll-rollup',
    animationDuration: '2.5s',
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'forwards',
    animationDirection: 'alternate',
    animationDelay: '1s',
    animationIterationCount: 2,
  }
});

export default withStyles(styles)(function RecordBar(props) {
  let classes = props.classes;
  const canvasRef = React.createRef();

  let iOSNotSafari = isiOS() && !isiOSSafari();

  let onClickRecord = () => {
    props.recorder && props.recorder.setVisualizedCanvas(canvasRef.current);
    props.onClickRecord && props.onClickRecord()
  }

  return (
    <div className={props.isRecording ? [classes.bar, classes.barRecording].join(' ') : classes.bar}>
      <div className={classes.grow} />
      {iOSNotSafari ?
        <div className={classes.notSafariExplainer}>
          Message sending disabled. Please use Safari to enable sending messages on iPhone/iPad.
        </div>
        :
        <React.Fragment>
          <div className={classes.micExplainerContainer} hidden={props.disabled || props.isRecording}>
            <div className={classes.micExplainer}>
              {props.message ||
                <span>Record a<br />response</span>}
            </div>
          </div>
          <canvas style={{
              width: '100px',
              height: '30px',
              marginRight: '16px',
            }}
            ref={canvasRef} />
          <div>
            <Fab color="primary"
                 aria-label="record"
                 disabled={props.disabled}
                 onClick={onClickRecord}
                 className={props.isRecording ? classes.micButtonRecording : classes.micButton}>
              {props.isRecording ?
                <StopIcon />
                :
                <MicIcon />
              }
            </Fab>
          </div>
          <div style={{
              width: '100px',
              height: '30px',
              marginLeft: '16px',
            }} />
          {props.currentRecordingDuration &&
            <div>Duration: <span id="duration">{props.currentRecordingDuration}ms</span></div>
          }
        </React.Fragment>
      }
      <div className={classes.grow}>
      </div>
    </div>
  );
});
