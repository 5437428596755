import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const styles = theme => ({
  title: {
    maxWidth: 'calc(100%-16px)',
    marginLeft: '8px',
    marginRight: '8px',
    marginBottom: '8px',
  },
  card: {
    maxWidth: 'calc(100%-16px)',
    marginLeft: '8px',
    marginRight: '8px',
    marginBottom: '8px',
  },
  cardContent: {
    padding: '8px 8px',
  },
  media: {
    height: 140,
  },
});

export default withStyles(styles)(function TopicChooserStep(props) {
  let handleClick = (topicSnapshot, event) => {
    props.onClick(topicSnapshot)
  }

  let classes = props.classes;

  return (
    <div>
      <Typography className={classes.title} variant='subtitle1'>Choose a topic for your conversation</Typography>
      {props.topics.map(topicSnapshot =>
        <Card className={classes.card} key={topicSnapshot.id} onClick={handleClick.bind(this, topicSnapshot)}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={topicSnapshot.get('photoUrl')}
              title={topicSnapshot.get('title')}
            />
            <CardContent className={classes.cardContent}>
              <Typography variant="body1">
                {topicSnapshot.get('title')}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
    </div>
  );
});
