import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { SnackbarProvider } from 'notistack';

import OnSnapshotController from './controllers/OnSnapshotController';

import SignInPage from './pages/SignInPage';
import ConversationsListPage from './pages/ConversationsListPage';
import PartnerConversationsListPage from './pages/PartnerConversationsListPage';
import PartnerConversationPage from './pages/PartnerConversationPage';
import ConversationPage from './pages/ConversationPage';
import SituationDemoPage from './pages/SituationDemoPage';
import AddPromptAudioPage from './pages/AddPromptAudioPage';
import AddAudioPage from './pages/AddAudioPage';
import WarmupPage from './pages/WarmupPage';
import WaitlistPage from './pages/WaitlistPage';
import PlacePage from './pages/PlacePage';
import PronunciationQuizPage from './pages/PronunciationQuizPage';
import HomePage from './pages/HomePage';

import firebase from 'firebase';

import { MuiThemeProvider, createMuiTheme, getContrastText } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

// All the following keys are optional.
// We try our best to provide a great default value.
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#E8F5E9',
      main: '#E8F5E9',
      dark: '#66BB6A',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    secondary: {
      light: '#81C784',
      main: '#4CAF50',
      dark: '#388E3C',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontSize: 20,
  }
});

console.log('theme', theme);

class PrivateRoute extends Component {
  state = {}

  componentWillMount() {
    this.deregisterFirebaseAuthListener = firebase.auth().onAuthStateChanged(this.setup.bind(this));
  }

  componentWillUnmount() {
    this.deregisterFirebaseAuthListener && this.deregisterFirebaseAuthListener();
  }

  setup(user) {
    this.setState({
      signedIn: user !== null,
      user: user,
    });
  }

  renderComponentOrWaitlist = (Component_, data, props) => {
    if (!data || !data.snapshot) {
      return null;
    }

    console.log('pathname', props.location.pathname)
    if (true || data.snapshot.get('user') === true
        || props.location.pathname === '/waitlist'
        || props.location.pathname === '/warmup') {
      return (<Component_ {...props} />)
    } else {
      return (
        <Redirect
          to={{
            pathname: '/waitlist',
            search: props.location.search,
            state: { from: props.location }
          }}
        />
      )
    }
  }

  renderComponent = (Component_, props) => {
    console.log('renderComponent', props)
    if (this.state.signedIn === false) {
      return (
        <Redirect
          to={{
            pathname: '/signin',
            search: `?next=${props.location.pathname}`,
            state: { from: props.location }
          }}
        />
      )
    } else {
      let rolesRef = firebase.firestore()
        .collection('users').doc(this.state.user.uid)
        .collection('internal').doc('roles');

      return (
        <OnSnapshotController
          key={rolesRef.path}
          dbRef={rolesRef}
          >
          {data => this.renderComponentOrWaitlist(Component_, data, props)}
        </OnSnapshotController>
      )
    }
  }

  render() {
    const { component: Component_, props, ...rest } = this.props
    if (this.state.signedIn === undefined) {
      return null;
    } else {
      return (
        <Route
          {...rest}
          render={props => this.renderComponent(Component_, props)}
        />
      );
    }
  }
}

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
               vertical: 'top',
               horizontal: 'center',
           }}
          maxSnack={3}>
        <Router>
          <Switch>
            <Route path="/signin" component={SignInPage}/>
            <PrivateRoute path="/u/:userId/c/:conversationId" component={PartnerConversationPage}/>
            <PrivateRoute path="/c/:conversationId" component={ConversationPage}/>
            <PrivateRoute path="/s/:situationId" component={SituationDemoPage}/>
            <PrivateRoute path="/partners" component={PartnerConversationsListPage}/>
            <PrivateRoute path="/conversations" component={ConversationsListPage}/>
            <PrivateRoute path="/prompts/add" component={AddPromptAudioPage}/>
            <PrivateRoute path="/add-audio" component={AddAudioPage}/>
            <PrivateRoute path="/waitlist" component={WaitlistPage}/>
            <PrivateRoute path="/place/:placeId" component={PlacePage}/>
            <Route path="/quiz" component={PronunciationQuizPage} />
            <Route path="/warmup" component={WarmupPage}/>
            <Route path="/" component={HomePage}/>
          </Switch>
        </Router>
        </SnackbarProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
