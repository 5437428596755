import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import VocabSelect from '../VocabSelect';

const styles = theme => ({
  main: {
    width: 'calc(100% - 16px)',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  vocabDiv: {
    marginBottom: '16px',
  },
});

export default withStyles(styles)(function FirstMessageStep(props) {
  let handleClick = (topicKey, event) => {
    props.onClick(topicKey)
  }

  let classes = props.classes;

  return (
    <div className={classes.main}>
      <Typography variant='subtitle1'>{props.prompt.text}</Typography>
      <Typography variant='subtitle2'>First, look up words any vocabulary you need</Typography>

      <div className={classes.vocabDiv}>
        <VocabSelect selectedItem={props.vocab} onSelectedItemChange={props.onChange} />
      </div>

      <Typography variant='subtitle2'>Now, record yourself describing the image</Typography>

    </div>
  );
});
