import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {yellow} from '@material-ui/core/colors';

import firebase from 'firebase';
import queryString from 'query-string'

import RecordBar from '../views/RecordBar';
import Message from '../views/Message';
import OnSnapshotController from '../controllers/OnSnapshotController';
import AsyncStorageData from '../controllers/AsyncStorageData';
import WzRecorder from '../WzRecorder';
import DokiboHeader from '../views/DokiboHeader';
import SituationUserResponsePrompt from '../components/SituationUserResponsePrompt';
import SituationOtherSpeakerMessage from '../components/SituationOtherSpeakerMessage';
import AudioVisualization from '../components/AudioVisualization';
import removePunctuation from '../helpers/removePunctuation';
import emailIsValid from '../helpers/emailIsValid';

let quizWords = `xiǎonuǒ
xuànlang
mǔtóu
luāntao
sēnjià
shuǎiruò
càosuó
zhōucān
māoshé
káodié
ái'ěn
shuǎnglün
cáyun
yōtuǎn
ràngsē
shuángài
shàngbiàn
sǒuxī
liélē
yùguǎn`.trim().split('\n');

let animationString = 'cubic-bezier(0.8, -0.22, 0.2, 1.0)';
let animationTime = '0.6s';

const styles = theme => ({
  main: {
    paddingBottom: theme.spacing(1),
    overflow: 'scroll',
  },
  container: {
    paddingTop: 'calc(64px + 8px)', // header is 64px on desktop, 56 on mobile
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    overflowX: 'hidden',
  },
  mainText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  warmupContainer: {
    marginBottom: theme.spacing(4),
  },
  word: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    border: '1px solid',
    marginTop: theme.spacing(4),
    borderColor: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[200],
  },
  wordThinking: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    border: '1px solid',
    marginTop: theme.spacing(4),
    borderColor: yellow[800],
    backgroundColor: yellow[100],
  },
  '@keyframes word-complete-celebration': {
    '0%': {
      borderColor: theme.palette.grey[800],
      backgroundColor: theme.palette.grey[200],
      transform: 'scale(1.0)',
    },
    '50%': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
      transform: 'scale(1.2)',
    },
    '100%': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.light,
      transform: 'scale(1.0)',
    },
  },
  wordComplete: {
    animationName: '$word-complete-celebration',
    animationDuration: '0.3s',
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'forwards',
    animationIterationCount: '1',

    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    border: '1px solid',
    marginTop: theme.spacing(4),
    borderColor: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
  },
  textField: {
    marginBottom: theme.spacing(4),
    width: '90%',
  },
  visualizer: {
    width: '80%',
    height: '200px',
  },
  wordAnimationContainer: {
    position: 'relative',
    width: '300%',
    height: '150px',
    // backgroundColor: theme.palette.primary.light,
  },
  wordContainerLeft: {
    width: '200px',
    position: 'absolute',
    left: '0%',
    transition: `left ${animationTime}`,
    transitionTimingFunction: animationString,
    marginBottom: theme.spacing(4),
  },
  wordContainer: {
    width: '200px',
    position: 'absolute',
    transition: `left ${animationTime}`,
    left: 'calc(50% - 100px)',
    transitionTimingFunction: animationString,
    marginBottom: theme.spacing(4),
  },
  wordContainerRight: {
    width: '200px',
    position: 'absolute',
    transition: `left ${animationTime}`,
    transitionTimingFunction: animationString,
    left: '100%',
    marginBottom: theme.spacing(4),
  },
  '@keyframes progress-timer': {
    '0%': {
      width: '0%',
    },
    '100%': {
      width: '100%',
    },
  },
  wordCountdown: {
    animationName: '$progress-timer',
    animationDuration: '5.0s',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
    animationDelay: animationTime,
    animationIterationCount: '1',
    width: '0%',
    height: '3px',
    marginTop: '-2px',
    backgroundColor: theme.palette.primary.dark,
  },
  dotsContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  lightDot: {
    width: '8px',
    height: '4px',
    marginLeft: '2px',
    marginRight: '2px',
    borderRadius: '0px',
    backgroundColor: theme.palette.primary.light,
    transition: `background-color ${animationTime}`,
    transitionTimingFunction: animationString,
  },
  darkDot: {
    width: '8px',
    height: '4px',
    marginLeft: '2px',
    marginRight: '2px',
    borderRadius: '0px',
    backgroundColor: theme.palette.primary.dark,
    transition: `background-color ${animationTime}`,
    transitionTimingFunction: animationString,
  },
});

class PronunciationQuizPage extends Component {
  state = {
    step: 'initial',
    wordPromptTimes: [],
    wordCompleteStatus: 'not-spoken',
    currentWord: 0,
    email: '',
  }

  constructor(props) {
    super(props);

    this.recorder = new WzRecorder({
      streaming: false,
      bufferSize: 4096,
      onRecordingStop: this.handleRecordingStop,
      onRecording: this.handleRecordingTick,
    });
  }

  componentDidMount() {
  }

  handleClickStart = () => {
    this.recorder.toggleRecording();
  }

  handleClickSubmitEmail = () => {
    console.log('handleClickSubmitEmail');

    var quizDocRef = firebase.firestore()
      .collection('users').doc(firebase.auth().currentUser.uid)
      .collection('quizzes').doc(this.state.quizDocId);

    quizDocRef.set({
      submittedEmail: this.state.email,
    }, {merge: true})

    this.setState({
      step: 'thanks',
    })
  }

  handleRecordingTick = (milliseconds) => {
    if (this.state.recordingStartedTimestamp === undefined) {
      this.setState({
        recordingStartedTimestamp: new Date(Date.now() - milliseconds),
      })
    }

    if (this.state.step === 'initial') {
      this.setState({
        step: 'warmup',
      });
    }
  }

  handleLoudnessThresholdSurpassed = (loundess) => {
    if (this.state.wordCompleteStatus === 'not-spoken') {
      this.setState({
        wordCompleteStatus: 'thinking',
      })
    }
  }

  wordCompleteClass = () => {
    if (this.state.wordCompleteStatus === 'not-spoken') {
      return this.props.classes.word;
    } else if (this.state.wordCompleteStatus === 'thinking') {
      return this.props.classes.word;
    } else if (this.state.wordCompleteStatus === 'spoken') {
      return this.props.classes.wordComplete;
    }
  }

  completeWarmupInternal = () => {
    this.setState({
      step: 'ongoing',
      wordPromptTimes: [new Date() - this.state.recordingStartedTimestamp],
      wordCompleteStatus: 'not-spoken',
    });

    setTimeout(() => {
      this.advanceToNextWord();
    }, 5 * 1000);
  }

  completeWarmup = () => {
    this.setState({
      wordCompleteStatus: 'spoken',
    });

    setTimeout(this.completeWarmupInternal, 0.5 * 1000);
  }

  advanceToNextWordInternal = () => {
    let timestamp = new Date() - this.state.recordingStartedTimestamp;

    let nextWord = this.state.currentWord + 1;
    if (nextWord === quizWords.length) {
      this.setState({
        step: 'finished',
      });
      this.recorder.toggleRecording();
      return;
    }

    this.setState({
      wordCompleteStatus: 'not-spoken',
      wordPromptTimes: [...this.state.wordPromptTimes, timestamp],
      currentWord: nextWord,
    });

    setTimeout(() => {
      this.advanceToNextWord();
    }, 5 * 1000);
  }

  advanceToNextWord = () => {
    this.setState({
      wordCompleteStatus: 'spoken',
    })

    setTimeout(this.advanceToNextWordInternal, 0.5 * 1000);
  }

  handleRecordingStop = (blob) => {
    console.log('handleRecordingStop with blob size ', blob.size, this.state.currentMessageId);

    var quizDocRef = firebase.firestore()
      .collection('users').doc(firebase.auth().currentUser.uid)
      .collection('quizzes').doc()

    this.setState({
      quizDocId: quizDocRef.id,
    })

    var storageRef = firebase.storage().ref()
      .child('users').child(firebase.auth().currentUser.uid)
      .child('quizzes').child(`${quizDocRef.id}-audio`)

    quizDocRef.set({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      audioState: 'uploading',
      wordPromptTimes: this.state.wordPromptTimes,
      quizWords: quizWords,
      audioDuration: new Date() - this.state.recordingStartedTimestamp,
      userId: firebase.auth().currentUser.uid,
    }).then(snapshot => storageRef.put(blob))
    .then(snapshot => quizDocRef.set({
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      audioPath: storageRef.fullPath,
      audioState: 'uploaded',
    }, {merge: true}))
    .then(snapshot => {
      console.log(`finished uploading audio for message`, quizDocRef.id)
    })
    .catch(error => {
      console.log(error);

      quizDocRef.set({
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
        error: error.toString(),
      }, {merge: true})
    });
  }

  handleEmailChange = (element) => {
    this.setState({
      email: element.target.value,
    })
  }

  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.main}>
        <DokiboHeader
          title='Allotone Pronunciation Quiz'
          breadcrumbs={[
            {title: 'Allotone'}
          ]}

        />
        <div className={classes.container}>
          {this.state.step === 'initial' &&
            <React.Fragment>
              <Typography className={classes.mainText} variant='h5'>Let's start with a microphone check</Typography>
              <Button onClick={this.handleClickStart} variant='contained' color='primary'>Get Started</Button>
            </React.Fragment>
          }
          {this.state.step === 'warmup' &&
            <React.Fragment>
              <Typography className={classes.mainText} variant='h5'>Let's start with a microphone check</Typography>
              <Typography className={classes.mainText} variant='h5'>Go ahead and say hello</Typography>
              <div className={classes.warmupContainer}>
                <Typography
                  className={classes.word}
                  variant='h5'>
                  nǐhǎo
                </Typography>
              </div>
              <AudioVisualization
                className={classes.visualizer}
                getAudioData={this.recorder.mostRecentRecordedChunk}
                onLoudnessThresholdSurpassed={this.handleLoudnessThresholdSurpassed} />
              <Button
                disabled={this.state.wordCompleteStatus !== 'thinking'}
                variant='contained' color='primary' className={classes.warmupContainer} onClick={this.completeWarmupInternal}>Next</Button>
            </React.Fragment>
          }
          {this.state.step === 'ongoing' &&
            <React.Fragment>
              <div className={classes.dotsContainer}>
                {quizWords.map((dot, i) =>
                  i < this.state.currentWord ?
                  <div key={i} className={classes.darkDot} />
                  :
                  <div key={i} className={classes.lightDot} />
                )}
              </div>
              <Typography className={classes.mainText} variant='h5'>Say this word</Typography>
              <div className={classes.wordAnimationContainer}>
                <div key={this.state.currentWord-1} className={classes.wordContainerLeft}>
                  {this.state.currentWord > 0 &&
                    <Typography
                      className={this.wordCompleteClass()}
                      variant='h5'>
                      {quizWords[this.state.currentWord-1]}
                    </Typography>
                  }
                </div>
                <div key={this.state.currentWord} className={classes.wordContainer}>
                  <Typography
                    className={this.wordCompleteClass()}
                    variant='h5'>
                    {quizWords[this.state.currentWord]}
                  </Typography>
                  <div className={classes.wordCountdown} />
                </div>
                <div key={this.state.currentWord+1} className={classes.wordContainerRight}>
                  {this.state.currentWord+1 < quizWords.length &&
                    <Typography
                      className={this.wordCompleteClass()}
                      variant='h5'>
                      {quizWords[this.state.currentWord+1]}
                    </Typography>
                  }
                </div>
              </div>
              <AudioVisualization
                className={classes.visualizer}
                getAudioData={this.recorder.mostRecentRecordedChunk} />
            </React.Fragment>
          }
          {this.state.step === 'finished' &&
            <React.Fragment>
              <Typography className={classes.mainText} variant='h5'>You're done! Enter your email to get your results</Typography>
              <TextField
                id="standard-name"
                label="Email"
                type="email"
                name="email"
                autoComplete="email"
                className={classes.textField}
                value={this.state.email}
                onChange={this.handleEmailChange}
                margin="normal"
              />
              <Button
                onClick={this.handleClickSubmitEmail}
                disabled={!emailIsValid(this.state.email)}
                variant='contained'
                color='primary'>
                Submit
              </Button>
            </React.Fragment>
          }
          {this.state.step === 'thanks' &&
            <React.Fragment>
              <Typography className={classes.mainText} variant='h5'>Thanks! Expect an email within 24 hours.</Typography>
              <Typography className={classes.mainText} variant='body1'>If you think a friend could benefit from this, please share!</Typography>
            </React.Fragment>
          }

          {false &&
            <Button onClick={this.advanceToNextWord}>Advance</Button>
          }
        </div>
      </div>
    );
  }
}


PronunciationQuizPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(PronunciationQuizPage);
