import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import {red} from '@material-ui/core/colors'

import firebase from 'firebase';

import RecordBar from '../views/RecordBar';
import OnSnapshotController from '../controllers/OnSnapshotController';
import AsyncStorageData from '../controllers/AsyncStorageData';
import WzRecorder from '../WzRecorder';
import DokiboHeader from '../views/DokiboHeader';

import util from 'util';

const RedButton = withStyles(theme => ({
  root: {
    color: red[500],
  },
}))(Button);

const styles = theme => ({
  container: {
    paddingTop: 'calc(56px)', // header is 56px
  },
  getStartedContainer: {
    marginTop: theme.spacing(2),
    paddingBottom: '87px', // 79 for RecordBar + 8 for margin.
    overflow: 'scroll',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  paragraph: {
    marginBottom: theme.spacing(1),
  },
  paragraphBold: {
    marginBottom: theme.spacing(1),
    fontWeight: '500',
  },
  audioActions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  audio: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  errorDiv: {
    border: `1px solid ${red[500]}`,
    background: red[100],
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: 'fit-content',
  }
});

class WarmupPage extends Component {
  state = {
  }

  constructor(props) {
    super(props);

    this.recorder = new WzRecorder({
      onRecordingStop: this.handleRecordingStop,
      onRecording: this.handleRecordingTick,
    });
  }

  handleClickRecord = () => {
    this.recorder.toggleRecording();
    console.log('toggle recording');
  }

  handleRecordingTick = (milliseconds) => {
    this.setState({
      currentRecordingDuration: milliseconds,
    })
  }

  handleRecordingStop = (blob) => {
    console.log('handleRecordingStop')

    this.setState({
      draftAudio: {
        data: blob,
        url: URL.createObjectURL(blob),
        duration: this.state.currentRecordingDuration,
      },
      currentRecordingDuration: undefined,
    })
  }

  handleRecordAgain = () => {
    this.setState({
      draftAudio: undefined,
    })

    this.handleClickRecord();
  }

  handleSubmit = () => {
    let userRef = firebase.firestore()
      .collection('users').doc(firebase.auth().currentUser.uid);

    let storageRef = firebase.storage().ref()
      .child('users').child(firebase.auth().currentUser.uid)
      .child('warmup-message-audio');

    this.setState({
      uploading: true,
    })

    storageRef.put(this.state.draftAudio.data)
    .then(snapshot => userRef.set({
      warmupAudio: {
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
        audioPath: storageRef.fullPath,
        durationMillis: this.state.draftAudio.duration,
        audioUploadError: firebase.firestore.FieldValue.delete(),
      },
    }, {merge: true}))
    .then(snapshot => {
      console.log(`finished uploading warmup audio for user`, userRef.id);
      this.setState({
        draftAudio: undefined,
        uploading: undefined,
      })

      this.props.history.push('/waitlist');
    })
    .catch(error => {
      console.log(error);

      this.setState({
        errorMessage: `Error, please email this code to help@dokibo.com: ${firebase.auth().currentUser.uid.substring(0, 4)}`,
      });

      userRef.set({
        warmupAudio: {
          modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
          audioUploadError: util.inspect(error),
        },
      }, {merge: true})
    });
  }


  render() {
    const classes = this.props.classes;

    return (
      <div>
        <DokiboHeader
          title='Dokibo | Warmup'
          hideSignInButton={true}
          breadcrumbs={[
            {title: 'Dokibo', href: `/`},
            {title: 'Get Started'}
          ]}

        />
        <div className={classes.container}>
          <div className={classes.getStartedContainer}>
            <Typography className={classes.title} variant='h6'>Get Started</Typography>
            <Typography className={classes.paragraph} variant='body2'>
              Let’s get you warmed up. Using your chinese, tell us about yourself.
            </Typography>
            <Typography className={classes.paragraph} variant='body2'>
              If you’re a beginner, this might include things like where you’re from, how many siblings you have and if you’ve been to China.
            </Typography>
            <Typography className={classes.paragraph} variant='body2'>
              If you’re a little more advanced, you might choose to go into detail about your chinese learning experience so far and what you’re looking for.
            </Typography>
            <Typography className={classes.paragraphBold} variant='body2'>
              When you're ready, tap the microphone at the bottom to start.
            </Typography>
            {this.state.draftAudio &&
              <React.Fragment>
                <audio className={classes.audio} controls src={this.state.draftAudio.url} />
                <div className={classes.audioActions}>
                  {!this.state.uploading &&
                    <React.Fragment>
                      <RedButton className={classes.button} onClick={this.handleRecordAgain}>Record Again</RedButton>
                      <Button variant='contained' color='primary' onClick={this.handleSubmit}>submit</Button>
                    </React.Fragment>
                  }
                </div>
                {this.state.uploading &&
                  <div>
                    <LinearProgress color='secondary' />
                    <Typography variant='body1'>uploading...</Typography>
                  </div>
                }
              </React.Fragment>
            }
            {this.state.errorMessage &&
              <div className={classes.errorDiv}>{this.state.errorMessage}</div>
            }
          </div>
          <RecordBar
            recorder={this.recorder}
            onClickRecord={this.handleClickRecord}
            isRecording={this.state.currentRecordingDuration !== undefined}
            message={<span>Tap to<br />record</span>}
            disabled={this.state.draftAudio !== undefined}
             />
        </div>
      </div>
    );
  }
}


WarmupPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(WarmupPage);
