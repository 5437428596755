// Import FirebaseAuth and firebase.
import React from 'react';
import firebase from 'firebase';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import DokiboHeader from '../views/DokiboHeader';

const styles = theme => ({
  container: {
    paddingTop: 'calc(64px)', // header is 64px
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1200px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
})

class WaitlistPage extends React.Component {
  componentDidMount() {
  }

  render() {
    let classes = this.props.classes;
    let userEmail = firebase.auth().currentUser.email;

    return (
      <div>
        <DokiboHeader
          title='Dokibo | Waitlist'
          breadcrumbs={[
            {title: 'Dokibo', href: '/'},
            {title: 'Waitlist'},
          ]}
        />
        <div className={classes.container}>
          <Typography variant='h4' className={classes.title}>You're on the waitlist</Typography>
          <Typography variant='body1' className={classes.subtitle}>
            Thank you for your interest in Dokibo. To ensure a high quality
            experience, we're adding more students as we can support them.
          </Typography>
          <Typography variant='body1'>
            No need to take any further action, we'll reach out to {userEmail} when we're ready for you.
          </Typography>
          <Typography variant='body1' className={classes.subtitle}>
            If you have any questions, please reach out to <a href="mailto:hello@dokibo.com">hello@dokibo.com</a>.
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(WaitlistPage);
