import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OnSnapshotController extends Component {
  // usage: must be used with a key=dbRef
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key

  componentDidMount() {
    this.props.dbRef &&
    this.props.dbRef.onSnapshot(snapshot => {
      this.setState({
        snapshot: snapshot,
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.dbRef !== this.props.dbRef) {
      console.warn('dbRef shouldn\'t change. Use key=dbRef to reset on dbRef update', prevProps.dbRef, this.props.dbRef)
    }
  }

  render() {
    let data = {
      ...this.state,
      props: this.props,
    };

    console.log('OnSnapshotController rendering', this.props.dbRef, data);
    return this.props.children(data)
  }
}

OnSnapshotController.propTypes = {
  dbRef: PropTypes.object.isRequired,
};

export default OnSnapshotController;
