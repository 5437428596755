import encodeAudioWorker from './workers/encodeAudio.worker';
import resizeCanvasToDisplaySize from './helpers/resizeCanvasToDisplaySize';
import voiceActivityDetection from 'voice-activity-detection';

/* global window */

function WzRecorder(config) {
  config = config || {};

  var self = this;
  var audioInput;
  var audioNode;
  var bufferSize = config.bufferSize || 4096;
  var recordedData = [];
  var recording = false;
  var recordingLength = 0;
  var startDate;
  var audioCtx;
  var hasVoice = false;
  var analyser;
  var visualizedCanvas;
  var recordedChunk = new Float32Array(bufferSize);

  this.toggleRecording = function() {
    recording ? self.stop() : self.start();
  }

  this.start = function() {
    // reset any previous data
    recordedData = [];
    recordingLength = 0;

    // webkit audio context shim
    audioCtx = new (window.AudioContext || window.webkitAudioContext)();

    if (audioCtx.createJavaScriptNode) {
        audioNode = audioCtx.createJavaScriptNode(bufferSize, 1, 1);
    } else if (audioCtx.createScriptProcessor) {
        audioNode = audioCtx.createScriptProcessor(bufferSize, 1, 1);
    } else {
        throw 'WebAudio not supported!';
    }

    audioNode.connect(audioCtx.destination);

    navigator.mediaDevices.getUserMedia({audio: true})
      .then(onMicrophoneCaptured)
      .catch(onMicrophoneError);
  };

  this.stop = function() {
    stopRecording(function(blob) {
      self.blob = blob;
      config.onRecordingStop && config.onRecordingStop(blob);
    });
  };

  this.mostRecentRecordedChunk = function() {
    return recordedChunk;
  }

  this.upload = function (url, params, callback) {
    var formData = new FormData();
    formData.append("audio", self.blob, config.filename || 'recording.wav');

    for (var i in params) {
      formData.append(i, params[i]);
    }

    var request = new XMLHttpRequest();
    request.upload.addEventListener("progress", function (e) {
        callback('progress', e, request);
    });

    request.upload.addEventListener("load", function (e) {
        callback('load', e, request);
    });

    request.onreadystatechange = function (e) {
      var status = 'loading';
      if (request.readyState == 4) {
        status = request.status == 200 ? 'done' : 'error';
      }
      callback(status, e, request);
    };

    request.open("POST", url);
    request.send(formData);
  };

  this.setVisualizedCanvas = function(canvas) {
    visualizedCanvas = canvas;
  }

  function setupVisualization(stream, canvas, visualizerConfig) {
    console.log('visualize', stream, canvas, visualizerConfig)

    visualizerConfig = visualizerConfig || {};

    var canvasCtx = canvas.getContext("2d");
    var source = audioCtx.createMediaStreamSource(stream);

    var analyser = audioCtx.createAnalyser();
    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 128;
    analyser.minDecibels = -60;
    analyser.maxDecibels = -30;
    var bufferLength = analyser.frequencyBinCount;
    var dataArray = new Uint8Array(bufferLength);
    var smoothedDataArray = new Uint8Array(bufferLength);
    var smoothedRandom = 0;

    for (var i = 0; i < bufferLength; i++) {
      smoothedDataArray[i] = 0;
    }

    source.connect(analyser);

    var stop = false;

    function stop() {
      stop = true;
    }

    function draw() {
      if (stop) {
        return;
      }
      if (canvas === null) {
        return;
      }

      resizeCanvasToDisplaySize(canvas);

      // get the canvas dimensions
      var width = canvas.width, height = canvas.height;

      // ask the browser to schedule a redraw before the next repaint
      requestAnimationFrame(draw);

      // clear the canvas
      canvasCtx.fillStyle = visualizerConfig.backcolor || '#ffffff';
      canvasCtx.fillRect(0, 0, width, height);

      if (!recording)
        return;

      canvasCtx.lineWidth = visualizerConfig.linewidth || 5;
      canvasCtx.strokeStyle = visualizerConfig.forecolor || '#f00';

      analyser.getByteFrequencyData(dataArray);

      canvasCtx.fillStyle = visualizerConfig.fillColor || '#f00';

      var bufferLengthWeCareAbout = 0.3 * bufferLength;

      var barWidth = (width / bufferLengthWeCareAbout)-1;
      var barHeight;
      var x = 0;

      smoothedRandom = 0.8 * smoothedRandom + 0.2 * (Math.random() * 10);

      for(var i = 0; i < bufferLengthWeCareAbout; i++) {
        barHeight = 0.8 * dataArray[i]; // + (bufferLength / 2 - Math.abs(bufferLength/2 - i)) * smoothedRandom;

        canvasCtx.fillStyle = 'rgb(50,' + (barHeight+100) + ',50)';
        canvasCtx.fillRect(x,height-0.9*barHeight,barWidth,0.9*barHeight);

        x += barWidth + 1;
      }

      // canvasCtx.beginPath();
      //
      // var sliceWidth = width * 1.0 / bufferLength;
      // var x = 0;
      //
      //
      // analyser.getByteTimeDomainData(dataArray);
      //
      // for (var i = 0; i < bufferLength; i++) {
      //
      //   smoothedDataArray[i] = 0.0 * 127 + 0.8 * smoothedDataArray[i] + 0.2 * dataArray[i];
      //   var v = dataArray[i] / 128.0;
      //   var y = v * height / 2;
      //
      //   i == 0 ? canvasCtx.moveTo(x, y) : canvasCtx.lineTo(x, y);
      //   x += sliceWidth;
      // }
      //
      // canvasCtx.lineTo(canvas.width, canvas.height/2);
      // canvasCtx.stroke();
    }

    draw();

    return stop;
  };

  function stopRecording(callback) {
    console.log('stopRecording');
    // stop recording
    recording = false;

    // to make sure onaudioprocess stops firing
    window.localStream.getTracks().forEach( (track) => { track.stop(); });
      audioInput.disconnect();
      audioNode.disconnect();

      if (config.streaming === true) {
        callback && callback();
      } else {
        exportWav({
          sampleRate: audioCtx.sampleRate,
          recordingLength: recordingLength,
          data: recordedData
        }, function(buffer, view) {
          self.blob = new Blob([view], { type: 'audio/wav' });
          callback && callback(self.blob);
        });
      }
    }


  function onMicrophoneCaptured(microphone) {
    // save the stream so we can disconnect it when we're done
    window.localStream = microphone;

    audioInput = audioCtx.createMediaStreamSource(microphone);
    audioInput.connect(audioNode);

    audioNode.onaudioprocess = onAudioProcess;

    if (visualizedCanvas !== undefined) {
      setupVisualization(microphone, visualizedCanvas, config.visualizerConfig);
    }

    recording = true;
    self.startDate = new Date();

    config.onRecordingStart && config.onRecordingStart();
    let sampleRate = audioCtx.sampleRate;

    // voiceActivityDetection(audioCtx, microphone, {
    //   minNoiseLevel: 0.3,
    //   onVoiceStart: function() {
    //     console.log('onVoiceStart');
    //     config.onVoiceStart && config.onVoiceStart();
    //     self.hasVoice = true;
    //   },
    //   onVoiceStop: function() {
    //     console.log('onVoiceStop');
    //     config.onVoiceStop && config.onVoiceStop();
    //     self.hasVoice = false;
    //   }
    // })
  }

  function onMicrophoneError(e) {
    console.log(e);
    alert('Unable to access the microphone.');
  }

  function onAudioProcess(e) {
    if (!recording) {
      return;
    }

    recordedChunk = new Float32Array(e.inputBuffer.getChannelData(0));
    self.duration = new Date().getTime() - self.startDate.getTime();

    if (config.streaming === true) {
      // console.log('recordedChunk.length', recordedChunk.length)
      var recordedBuffer = new ArrayBuffer(recordedChunk.length * 2);
      var view = new DataView(recordedBuffer);

      var index = 0;

      for (var i = 0; i < recordedChunk.length; i++) {
          view.setInt16(index, recordedChunk[i] * 0x7FFF, true);
          index += 2;
      }

      config.onRecording && config.onRecording(self.duration, view, self.hasVoice);
    } else {
      recordedData.push(recordedChunk);
      recordingLength += bufferSize;

      self.recordingLength = recordingLength;

      config.onRecording && config.onRecording(self.duration);
    }
  }


  function exportWav(config, callback) {
    console.log('exportWav');
    var webWorker = new encodeAudioWorker();
    webWorker.addEventListener('message', event => callback(event.data.buffer, event.data.view));
    webWorker.postMessage(config);
  }
}

export default WzRecorder;
