import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import firebase from 'firebase';
import moment from 'moment';

import OnSnapshotController from '../controllers/OnSnapshotController';
import DokiboHeader from '../views/DokiboHeader';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';


const styles = theme => ({
  container: {
    paddingTop: 'calc(64px)', // header is 64px
  },
  conversationsList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  conversationItem: {
    width: `calc(100% - ${theme.spacing(2)})`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: '4em',
    borderBottom: `1px solid ${theme.palette.divider}`,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&hover': {
      textDecoration: 'underline',
    }
  },
  userPhoto: {
    width: '2.5em',
    height: '2.5em',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  grow: {
    flexGrow: '1',
  },
});

class PartnerConversationsListPage extends Component {
  state = {

  }

  componentDidMount() {
  }

  componentWillUpdate(nextProps, nextState) {
  }

  getUserConversationLink = (userId, conversationId) => {
    return `/u/${userId}/c/${conversationId}`
  }

  partnerConversationsListRef = () => {
    return firebase.firestore()
      .collection('users').doc(firebase.auth().currentUser.uid)
      .collection('partnerConversations');
  }


  renderConversations = (snapshot) => {
    let classes = this.props.classes;

    var elements = [];
    console.log('partnerConversations', this.partnerConversationsListRef().path, snapshot);

    snapshot.forEach(conversation => {
      let modifiedAt = conversation.get('modifiedAt');
      let timeText = `${moment(modifiedAt.toDate()).fromNow().toLowerCase()}`
      let learnerUserRef = firebase.firestore().collection('users').doc(conversation.get('userId'))
                                               .collection('public').doc('userData');

      elements.push(
        <OnSnapshotController
          key={learnerUserRef.path}
          dbRef={learnerUserRef}
          >
          {data => data && data.snapshot &&
            <a key={conversation.get('conversationId')}
               className={classes.conversationItem}
               href={this.getUserConversationLink(conversation.get('userId'), conversation.get('conversationId'))}>
               <img className={classes.userPhoto} src={data.snapshot.get('photoURL')} />
               <span className={classes.grow}>
                 {data.snapshot.get('displayName')} ({timeText})
               </span>
               <ChevronRightIcon />
            </a>
            || null
          }
        </OnSnapshotController>
      );
    });

    return elements;
  }

  render() {
    const classes = this.props.classes;

    return (
      <div>
        <DokiboHeader
          title='Dokibo | Partner Conversations'
          breadcrumbs={[
            {title: 'Dokibo', href: `/`},
            {title: 'Partner Conversations'},
          ]}
        />
        <div className={classes.container}>
          <OnSnapshotController
            key={this.partnerConversationsListRef().path}
            dbRef={this.partnerConversationsListRef().orderBy('modifiedAt', 'desc')}
            >
            {data =>
              <div className={classes.conversationsList}>
                {data && data.snapshot &&
                  this.renderConversations(data.snapshot)
                }
              </div>
            }
          </OnSnapshotController>
        </div>
      </div>
    );
  }
}


PartnerConversationsListPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PartnerConversationsListPage);
