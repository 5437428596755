import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import React, { Fragment } from 'react';

import IconButton from '@material-ui/core/IconButton';

import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import Replay5Icon from '@material-ui/icons/Replay5';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import speak from '../helpers/speak';

let styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainRow: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    borderWidth: '1px',
    borderColor: theme.palette.grey[500],
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  expandedContent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: -theme.shape.borderRadius,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    borderWidth: '1px',
    borderColor: theme.palette.grey[500],
    borderLeftStyle: 'solid',
    borderRightStyle: 'solid',
    borderBottomStyle: 'solid',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  button: {
  },
  grow: {
    flexGrow: 1,
  }
});

class AudioPlayer extends React.Component {
  static displayName = "AudioPlayer";

  state = {
    expanded: this.props.defaultExpanded,
    canPlay: true, // iOS doesn't autoload and therefore this needs to be set manually
  };

  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
  }

  handleClickReplay5 = () => {
    let newTime = this.audioRef.current.currentTime - 5;
    if (newTime < 0) {
      newTime = 0;
    }
    this.audioRef.current.currentTime = newTime;
  }

  handleClickPause = () => {
    this.audioRef.current.pause();
  }

  handleClickPlay = () => {
    if (this.props.src === undefined) {
      return speak(this.props.transcript, 1, 'zh-CN', 1, this.handleOnEnded);
    }
    // need to check if play worked
    // https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/
    let promise = this.audioRef.current.play();

    if (promise !== undefined) {
      promise.then(() => {
        console.log('handleClickPlay: success')
      }).catch(error => {
        console.error('handleClickPlay: this.audioRef.current.play() failed', error.toString())
      });
    }
  }

  handleClickExpandMore = () => {
    this.setState({
      expanded: true,
    })
  }

  handleClickExpandLess = () => {
    this.setState({
      expanded: undefined,
    })
  }

  handleOnPlay = (e) => {
    this.setState({
      playing: true,
      buffering: undefined,
    });

    this.props.onPlay && this.props.onPlay(e);
  }

  handleOnPause = (e) => {
    this.setState({
      playing: undefined,
    });

    this.props.onPause && this.props.onPause(e);

  }

  handleOnEnded = (e) => {
    this.props.onEnded && this.props.onEnded(e);
  }

  handleOnTimeUpdate = (e) => {
    var buffered = undefined;
    if (this.audioRef.current.buffered.length > 0) {
      buffered = this.audioRef.current.buffered.end(0);
    } else {
      console.warn('handleOnTimeUpdate: no buffered segments', e.target, this.audioRef.current);
    }

    this.setState({
      currentTime: this.audioRef.current.currentTime,
      duration: this.audioRef.current.duration,
      buffered: buffered,
    })
  }

  handleOnProgress = (e) => {
    var buffered = undefined;
    if (this.audioRef.current.buffered.length > 0) {
      buffered = this.audioRef.current.buffered.end(0);
    } else {
      console.warn('handleOnProgress: no buffered segments', e.target, this.audioRef.current);
    }

    this.setState({
      buffered: buffered,
    })
  }

  handleOnCanPlay = () => {
    this.setState({
      canPlay: true,
    })
  }

  handleOnWaiting = () => {
    this.setState({
      buffering: true,
    })
  }

  setAudioRefs = (ref) => {
    this.audioRef.current = ref;
    if (this.props.audioRef !== undefined) {
      this.props.audioRef.current = ref;
    }
  }

  render() {
    let classes = this.props.classes;

    let completed = (this.state.currentTime || 0) / (this.state.duration || 1) * 100;
    let buffered = (this.state.buffered || 0) / (this.state.duration || 1) * 100;

    return (
      <Fragment>
        <audio
          ref={this.setAudioRefs}
          onPlay={this.handleOnPlay}
          onPause={this.handleOnPause}
          onEnded={this.handleOnEnded}
          onCanPlay={this.handleOnCanPlay}
          onTimeUpdate={this.handleOnTimeUpdate}
          onWaiting={this.handleOnWaiting}
          src={this.props.src}
          autoplay={this.props.autoplay}
          >
        </audio>

        <div className={classes.container}>
          <div className={classes.mainRow}>
            <IconButton disabled={!this.state.canPlay} onClick={this.handleClickReplay5} size='small' className={classes.button} aria-label="rewind 5 seconds">
              <Replay5Icon />
            </IconButton>
            {this.state.playing ?
              <IconButton onClick={this.handleClickPause} size='small' className={classes.button} aria-label="pause">
                <PauseCircleFilledIcon />
              </IconButton>
              :
              <IconButton disabled={!this.state.canPlay} onClick={this.handleClickPlay} size='small' className={classes.button} aria-label="play">
                <PlayCircleFilledIcon />
              </IconButton>
            }
            <LinearProgress className={classes.grow} color="secondary" variant={this.state.buffering ? 'buffer' : 'determinate'} value={completed} valueBuffer={buffered} />
            {this.state.expanded ?
              <IconButton onClick={this.handleClickExpandLess} size='small' className={classes.button} aria-label="less">
                <ExpandLessIcon />
              </IconButton>
              :
              <IconButton onClick={this.handleClickExpandMore} size='small' className={classes.button} aria-label="more">
                <ExpandMoreIcon />
              </IconButton>
            }
          </div>
          {this.state.expanded &&
            <div className={classes.expandedContent}>
              {this.props.expandedContent}
            </div>
          }
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AudioPlayer);
