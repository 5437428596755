import React, { Component } from 'react';
import PropTypes from 'prop-types';

import util from 'util';

class AsyncStorageData extends Component {
  // usage: must be used with a key=storageRef
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key

  componentDidMount() {
    this.props.storageRef &&
    this.props.storageRef.getDownloadURL().then((url) => {
      this.setState({
        downloadUrl: url,
      })
    }).catch((error) => {
      console.log(util.inspect(error));
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.storageRef !== this.props.storageRef) {
      console.warn('storageRef shouldn\'t change. Use key=storageRef to reset on storageRef update', prevProps.storageRef, this.props.storageRef)
    }
  }

  render() {
    let data = {
      ...this.state,
      ...this.props,
    };

    console.log('AsyncStorageData rendering', this.props.storageRef, data);
    return this.props.children(data)
  }
}

AsyncStorageData.propTypes = {
  storageRef: PropTypes.object.isRequired,
};

export default AsyncStorageData;
