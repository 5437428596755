import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import firebase from 'firebase';

import RecordBar from '../views/RecordBar';
import Message from '../views/Message';
import VocabSelect from '../VocabSelect';
import OnSnapshotController from '../controllers/OnSnapshotController';
import AsyncStorageData from '../controllers/AsyncStorageData';
import WzRecorder from '../WzRecorder';
import DokiboHeader from '../views/DokiboHeader';


const styles = theme => ({
  container: {
    paddingTop: 'calc(64px)', // header is 64px
  },
  conversation: {
    marginBottom: '87px', // 79 for RecordBar + 8 for margin.
    overflow: 'scroll',
  },
  messagesContainer: {
    marginLeft: '8px',
    marginRight: '8px',
  },
  containerDiv: {
    width: 'calc(100% - 16px)',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
  },
});

class ConversationPage extends Component {
  state = {
  }

  constructor(props) {
    super(props);

    this.recorder = new WzRecorder({
      onRecordingStop: this.handleRecordingStop,
      onRecording: this.handleRecordingTick,
    });
  }

  componentDidMount() {
    this.conversationRef().onSnapshot(snapshot => {
        console.log('conversationSnapshot', snapshot, snapshot.get('topicId'));
        if (snapshot.exists === false) {
          console.warn('no partner conversation exists')
        } else {
          this.setState({
            snapshot: snapshot,
          })
        }
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.conversationId !== this.props.match.params.conversationId) {
      console.warn('conversationId shouldn\'t change. Use key=conversationId to reset on conversationId update')
    }
  }

  conversationRef = () => {
    return firebase.firestore()
      .collection('users').doc(this.props.match.params.userId)
      .collection('conversations').doc(this.props.match.params.conversationId)
  }

  handleClickRecord = () => {
    this.recorder.toggleRecording();
    console.log('toggle recording');
  }

  handleRecordingTick = (milliseconds) => {
    this.setState({
      currentRecordingDuration: milliseconds,
    })
  }

  handleRecordingStop = (blob) => {
    console.log('handleRecordingStop')

    let recordingDuration = this.state.currentRecordingDuration;
    var messageRef = this.conversationRef()
      .collection('messages').doc();

    var storageRef = firebase.storage().ref()
      .child('users').child(this.props.match.params.userId)
      .child('conversations').child(this.conversationRef().id)
      .child('audioForUser').child(firebase.auth().currentUser.uid)
      .child(`${messageRef.id}`);

    messageRef.set({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      audioState: 'uploading',
      userId: firebase.auth().currentUser.uid,
    }).then(snapshot => storageRef.put(blob))
    .then(snapshot => messageRef.set({
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      audioPath: storageRef.fullPath,
      audioState: 'uploaded',
      durationMillis: recordingDuration,
    }, {merge: true}))
    .then(snapshot => {
      this.conversationRef().set({
        state: 'ongoing-conversation',
      }, {merge: true})
    })
    .then(snapshot => {
      console.log(`finished uploading audio for message`, messageRef.id)
    })
    .catch(error => {
      console.log(error);

      messageRef.set({
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
        error: error.toString(),
      }, {merge: true})
    });

    this.setState({
      currentRecordingDuration: undefined,
    })
  }

  renderMessages = (snapshot) => {
    var elements = [];
    var audioRef;
    var nextAudioRef;

    snapshot.forEach(message => {
      console.log('audioPath', message.get('audioPath'))

      if (message.get('audioPath') !== undefined) {
        audioRef = nextAudioRef || React.createRef();
        nextAudioRef = React.createRef();

        elements.push(
          <AsyncStorageData
            key={message.id}
            storageRef={firebase.storage().ref(message.get('audioPath'))}
            audioRef={audioRef}
            nextAudioRef={nextAudioRef}
            >
            {data => data &&
              <Message
                snapshot={message}
                audioSrc={data.downloadUrl}
                audioRef={data.audioRef}
                nextAudioRef={data.nextAudioRef} />}
          </AsyncStorageData>
        );
      } else {
        elements.push(<Message snapshot={message} />)
      }
    })

    return elements;
  }

  render() {
    const classes = this.props.classes;

    if (this.state.snapshot === undefined) {
      return null;
    }

    return (
      <div className={classes.conversation}>
        <DokiboHeader
          title='Dokibo | Partner Conversation'
          breadcrumbs={[
            {title: 'Dokibo', href: `/`},
            {title: 'Partners', href: '/partners'},
            {title: 'Conversation'}
          ]}

        />
      <div className={classes.container}>
          <OnSnapshotController
            key={firebase.firestore().collection('prompts').doc(this.state.snapshot.get('promptId')).path}
            dbRef={firebase.firestore().collection('prompts').doc(this.state.snapshot.get('promptId'))}
            >
            {data => data && data.snapshot &&
              <img style={{width: '100%'}} src={data.snapshot.get('photoUrl')} />
              || null
            }
          </OnSnapshotController>

          <OnSnapshotController
            key={this.conversationRef().collection('messages').path}
            dbRef={this.conversationRef().collection('messages').orderBy('createdAt', 'asc')}
            >
            {data =>
              <div className={classes.messagesContainer}>
                {data && data.snapshot &&
                  this.renderMessages(data.snapshot)
                }
              </div>
            }
          </OnSnapshotController>

          <RecordBar
            onClickRecord={this.handleClickRecord}
            isRecording={this.state.currentRecordingDuration !== undefined}
             />
        </div>
      </div>
    );
  }
}


ConversationPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConversationPage);
