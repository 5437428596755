import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import firebase from 'firebase';

import RecordBar from '../views/RecordBar';
import TopicChooserStep from '../views/TopicChooserStep';
import FirstMessageStep from '../views/FirstMessageStep';
import Message from '../views/Message';
import VocabSelect from '../VocabSelect';
import OnSnapshotController from '../controllers/OnSnapshotController';
import AsyncStorageData from '../controllers/AsyncStorageData';
import WzRecorder from '../WzRecorder';
import DokiboHeader from '../views/DokiboHeader';


const styles = theme => ({
  container: {
    paddingTop: 'calc(64px)', // header is 64px
  },
  conversation: {
    paddingBottom: '87px', // 79 for RecordBar + 8 for margin.
    overflow: 'scroll',
  },
  messagesContainer: {
    marginLeft: '8px',
    marginRight: '8px',
  },
  containerDiv: {
    width: 'calc(100% - 16px)',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
  },
});

class ConversationPage extends Component {
  state = {
  }

  constructor(props) {
    super(props);

    this.recorder = new WzRecorder({
      onRecordingStop: this.handleRecordingStop,
      onRecording: this.handleRecordingTick,
    });
  }

  componentDidMount() {
    this.conversationRef().onSnapshot(snapshot => {
        console.log('conversationSnapshot', snapshot, snapshot.get('topicId'));
        if (snapshot.exists === false) {
          snapshot.ref.set({
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
          var introMessageRef = this.conversationRef()
            .collection('messages').doc();
          introMessageRef.set({
            audioPath: 'recordings/DokiboSpeakHelloMessage.mp4',
            audioState: 'uploaded',
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            durationMillis: '28000',
            modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
            userId: 'X5O6yGPYTUdN9W4oMFdXPHr6Iah2',
          })
        } else {
          this.setState({
            snapshot: snapshot,
          })
        }
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.conversationId !== this.props.match.params.conversationId) {
      console.warn('conversationId shouldn\'t change. Use key=conversationId to reset on conversationId update')
    }
  }

  conversationRef = () => {
    return firebase.firestore()
      .collection('users').doc(firebase.auth().currentUser.uid)
      .collection('conversations').doc(this.props.match.params.conversationId)
  }

  handleClickTopic = (topicSnapshot) => {
    console.log('handleClickTopic', topicSnapshot);

    this.conversationRef().set({
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      topicId: topicSnapshot.id,
      state: 'first-message',
      promptId: topicSnapshot.get('prompts')[0],
    }, {merge: true});
  }

  handleVocabSelectedItemChange = (items) => {
    console.log('new vocab', items);
    this.conversationRef().set({
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      vocab: items,
    }, {merge: true});
  }

  handleClickRecord = () => {
    this.recorder.toggleRecording();
    console.log('toggle recording');
  }

  handleRecordingTick = (milliseconds) => {
    this.setState({
      currentRecordingDuration: milliseconds,
    })
  }

  handleRecordingStop = (blob) => {
    console.log('handleRecordingStop with blob size ', blob.size)

    let recordingDuration = this.state.currentRecordingDuration;
    var messageRef = this.conversationRef()
      .collection('messages').doc();

    var storageRef = firebase.storage().ref()
      .child('users').child(firebase.auth().currentUser.uid)
      .child('conversations').child(this.conversationRef().id)
      .child('audioForUser').child(firebase.auth().currentUser.uid)
      .child(`${messageRef.id}-audio`);

    messageRef.set({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      audioState: 'uploading',
      userId: firebase.auth().currentUser.uid,
    }).then(snapshot => storageRef.put(blob))
    .then(snapshot => messageRef.set({
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      audioPath: storageRef.fullPath,
      audioState: 'uploaded',
      durationMillis: recordingDuration,
    }, {merge: true}))
    .then(snapshot => {
      this.conversationRef().set({
        state: 'ongoing-conversation',
      }, {merge: true})
    })
    .then(snapshot => {
      console.log(`finished uploading audio for message`, messageRef.id)
    })
    .catch(error => {
      console.log(error);

      messageRef.set({
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
        error: error.toString(),
      }, {merge: true})
    });

    this.setState({
      currentRecordingDuration: undefined,
    })
  }

  getStep = () => {
    if (this.state.snapshot === undefined) {
      return 'pending-db-creation';
    } else if (this.state.snapshot.get('topicId') === undefined) {
      return 'choose-topic';
    } else {
      console.log('state', this.state.snapshot.get('state'))
      return this.state.snapshot.get('state');
    }
  }

  renderMessages = (snapshot) => {
    var elements = [];
    var audioRef;
    var nextAudioRef;

    snapshot.forEach(message => {
      console.log('audioPath', message.get('audioPath'))

      if (message.get('audioPath') !== undefined) {
        audioRef = nextAudioRef || React.createRef();
        nextAudioRef = React.createRef();

        elements.push(
          <AsyncStorageData
            key={message.id}
            storageRef={firebase.storage().ref(message.get('audioPath'))}
            audioRef={audioRef}
            nextAudioRef={nextAudioRef}
            message={message}
            >
            {data => data &&
              <Message
                defaultExpanded={data.message.get('userId') !== firebase.auth().currentUser.uid}
                snapshot={message}
                audioSrc={data.downloadUrl}
                audioRef={data.audioRef}
                nextAudioRef={data.nextAudioRef} />}
          </AsyncStorageData>
        );
      } else {
        elements.push(<Message snapshot={message} />)
      }
    })

    return elements;
  }

  render() {
    const classes = this.props.classes;

    let firstMessageDownloadUrl = 'https://firebasestorage.googleapis.com/v0/b/dokibo-speak-prod.appspot.com/o/recordings%2FDokiboSpeakHelloMessage.mp4?alt=media&token=6fb718e6-7bc5-4a62-be7e-dff66a9fccee';

    return (
      <div className={classes.conversation}>
        <DokiboHeader
          title='Dokibo | Conversation'
          breadcrumbs={[
            {title: 'Dokibo', href: `/conversations`},
            {title: 'Conversation'}
          ]}

        />
        <div className={classes.container}>
          {this.getStep() === 'choose-topic' &&
            <OnSnapshotController
              key={firebase.firestore().collection('topics').path}
              dbRef={firebase.firestore().collection('topics')}
              >
              {data => data && data.snapshot &&
                <TopicChooserStep
                  topics={data.snapshot.docs}
                  onClick={this.handleClickTopic} />
                || null
              }
            </OnSnapshotController>

          }

          {(
            this.getStep() === 'first-message' ||
            this.getStep() === 'ongoing-conversation'
           ) &&
            <OnSnapshotController
              key={firebase.firestore().collection('prompts').doc(this.state.snapshot.get('promptId')).path}
              dbRef={firebase.firestore().collection('prompts').doc(this.state.snapshot.get('promptId'))}
              >
              {data => data && data.snapshot &&
                <img style={{width: '100%'}} src={data.snapshot.get('photoUrl')} />
                || null
              }
            </OnSnapshotController>
          }

          {(
            this.getStep() === 'first-message' ||
            this.getStep() === 'ongoing-conversation'
           ) &&
            <OnSnapshotController
              key={this.conversationRef().collection('messages').path}
              dbRef={this.conversationRef().collection('messages').orderBy('createdAt', 'asc')}
              >
              {data =>
                <div className={classes.messagesContainer}>
                  {data && data.snapshot &&
                    this.renderMessages(data.snapshot)
                  }
                </div>
              }
            </OnSnapshotController>
          }

          {this.getStep() === 'first-message' &&
            <Typography className={classes.containerDiv} variant='subtitle1'>
              Give the message above a listen and then record a response.
            </Typography>
          }

          {(
            this.getStep() === 'first-message' ||
            this.getStep() === 'ongoing-conversation'
           ) &&
            <div className={classes.containerDiv}>
              <VocabSelect selectedItem={this.state.snapshot.get('vocab') || []}
                           onSelectedItemChange={this.handleVocabSelectedItemChange}
                            />
            </div>

          }

          {(
            this.getStep() === 'first-message' ||
            this.getStep() === 'ongoing-conversation'
           ) &&
            <RecordBar
              onClickRecord={this.handleClickRecord}
              isRecording={this.state.currentRecordingDuration !== undefined}
               />
          }
        </div>
      </div>
    );
  }
}


ConversationPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConversationPage);
