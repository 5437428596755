import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import firebase from 'firebase';
import moment from 'moment';

import OnSnapshotController from '../controllers/OnSnapshotController';
import DokiboHeader from '../views/DokiboHeader';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';


const styles = theme => ({
  container: {
    paddingTop: 'calc(64px)', // header is 64px
  },
  conversationsList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  conversationItem: {
    width: `calc(100% - ${theme.spacing(2)})`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: '4em',
    borderBottom: `1px solid ${theme.palette.divider}`,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&hover': {
      textDecoration: 'underline',
    }
  },
  grow: {
    flexGrow: '1',
  },
});

class ConversationsListPage extends Component {
  state = {

  }

  componentDidMount() {
  }

  componentWillUpdate(nextProps, nextState) {
  }

  getConversationLink = conversationId => {
    return `/c/${conversationId}`
  }

  conversationListRef = () => {
    return firebase.firestore()
      .collection('users').doc(firebase.auth().currentUser.uid)
      .collection('conversations');
  }

  renderConversation = (id, title) => {
    let classes = this.props.classes;

    return (
      <a key={id}
         className={this.props.classes.conversationItem}
         href={this.getConversationLink(id)}>
         <span className={classes.grow}>
           {title}
         </span>
         <ChevronRightIcon />
      </a>
    )
  }

  renderConversations = (snapshot) => {
    let newConversationId = firebase.firestore()
      .collection('users').doc(firebase.auth().currentUser.uid)
      .collection('conversations').doc().id;

    var elements = [
      this.renderConversation(newConversationId, 'Start New Conversation'),
    ];

    snapshot.forEach(conversation => {
      var timeText = '';
      let modifiedAt = conversation.get('modifiedAt');
      if (modifiedAt === undefined) {
        timeText = `${moment(conversation.get('createdAt').toDate()).fromNow().toLowerCase()}`
      } else {
        timeText = `${moment(modifiedAt.toDate()).fromNow().toLowerCase()}`
      }

      elements.push(
        this.renderConversation(conversation.id,
           `${conversation.get('topicId') || 'Conversation'} (${timeText})`)
      );
    })

    return elements;
  }

  render() {
    const classes = this.props.classes;

    return (
      <div>
        <DokiboHeader
          title='Dokibo | Conversations'
          breadcrumbs={[
            {title: 'Dokibo', href: `/`}
          ]}
        />
        <div className={classes.container}>
          <OnSnapshotController
            key={this.conversationListRef().path}
            dbRef={this.conversationListRef().orderBy('modifiedAt', 'desc')}
            >
            {data =>
              <div className={classes.conversationsList}>
                {data && data.snapshot &&
                  this.renderConversations(data.snapshot)
                }
              </div>
            }
          </OnSnapshotController>
        </div>
      </div>
    );
  }
}


ConversationsListPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConversationsListPage);
