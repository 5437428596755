import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import firebase from 'firebase';

import DokiboHeader from '../views/DokiboHeader';


const styles = theme => ({
  container: {
    paddingTop: 'calc(56px)', // header is 56px
  },
  content: {
    //paddingBottom: '87px', // 79 for RecordBar + 8 for margin.
    overflow: 'scroll',
    width: `calc(100% - ${theme.spacing(2)}px)`,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
  },
  placeImage: {
    width: '100%',
  },
  actionsList: {
    display: 'flex',
    flexDirection: 'column',
  },
  actionItem: {
    height: '2em',
  }
});

class PlacePage extends Component {
  state = {

  }

  componentDidMount() {
    this.placeRef().onSnapshot(snapshot => {
      console.log('placeSnapshot', snapshot);
      this.setState({
        placeSnapshot: snapshot,
      });
    });

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.placeId !== this.props.match.params.placeId) {
      console.warn('placeId shouldn\'t change. Use key=placeId to reset on placeId update')
    }
  }

  placeRef = () => {
    return firebase.firestore()
      .collection('places').doc(this.props.match.params.placeId)
  }

  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.conversation}>
        <DokiboHeader
          title='Dokibo | Conversation'
          breadcrumbs={[
            {title: 'Dokibo', href: `/`},
            {title: this.state.placeSnapshot && this.state.placeSnapshot.get('title') || ''}
          ]}

        />
        <div className={classes.container}>
          {this.state.placeSnapshot &&
            <React.Fragment>
              <img className={classes.placeImage} src={this.state.placeSnapshot.get('headerImageUri')} />
              <div className={classes.content}>
                <Typography variant="body2">{this.state.placeSnapshot.get('introduction')}</Typography>
                {this.state.placeSnapshot.get('sections').map(section =>
                  <React.Fragment key={section.title}>
                    <Typography variant="h6">{section.title}</Typography>
                    <div className={classes.actionsList}>
                      {section.items.map(item =>
                        <a key={item.title} className={classes.actionItem} href={item.url}>{item.title}</a>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>

            </React.Fragment>
          }
        </div>
      </div>
    );
  }
}


PlacePage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(PlacePage);
