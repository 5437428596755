import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { red } from '@material-ui/core/colors';
import Modal from '@material-ui/core/Modal';

import firebase from 'firebase';
import moment from 'moment';

import OnSnapshotController from '../controllers/OnSnapshotController';
import AudioPlayer from '../components/AudioPlayer';

var currentlyPlayingAudioRef = null;

const RedButton = withStyles(theme => ({
  root: {
    color: red[500],
  },
}))(Button);

const styles = theme => ({
  messageRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  profilePhoto: {
    width: '48px',
    height: '48px',
    backgroundColor: theme.palette.grey[100],
    borderRadius: '50%',
    marginTop: '3px',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  messageCol: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  context: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  button: {
    width: 'fit-content',
    fontSize: '75%',
  },
  modalStyle: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    width: 400,
    maxWidth: 'calc(100% - 86px)', // derived emperically
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  transcript: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: 'x-large',
  },
});

export default withStyles(styles)(function Message(props) {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [showUserTranscript, setShowUserTranscript] = React.useState(false);
  const [showNativeSpeakerTranscript, setShowNativeSpeakerTranscript] = React.useState(false);


  let classes = props.classes;
  let userRef = firebase.firestore().collection('users').doc(props.messageSnapshot.get('userId'))
                                    .collection('public').doc('userData');


  let onClickUserTranscript = () => {
    console.log('onClickUserTranscript')
    setShowUserTranscript(!showUserTranscript);
  }

  let onClickNativeSpeakerTranscript = () => {
    console.log('onClickNativeSpeakerTranscript')
    setShowNativeSpeakerTranscript(!showNativeSpeakerTranscript);
  }

  let onClickDelete = () => {
    // console.log('onClickDelete')
    // setDeleteModalOpen(true)
  }

  let handleDeleteConfirmed = () => {
    console.log('handleDeleteConfirmed')
    setDeleteModalOpen(false);

    if (props.messageSnapshot.get('audioPath') !== undefined) {
      var storageRef = firebase.storage().ref(props.messageSnapshot.get('audioPath'));
      console.log('deleting stored audio at', storageRef.fullPath);
      storageRef.delete();
    }

    console.log('deleting message at', props.messageSnapshot.ref.path);
    props.messageSnapshot.ref.delete();
  }

  let handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };


  let handlePlay = (event) => {
    if (currentlyPlayingAudioRef !== null) {
      currentlyPlayingAudioRef.pause();
    }

    currentlyPlayingAudioRef = event.target;
  }

  let handlePause = (event) => {
    if (currentlyPlayingAudioRef === event.target) {
      currentlyPlayingAudioRef = null;
    }
  }


  let handleEnded = () => {
    console.log('ended', props.nextAudioRef, props.audioRef);
    if (props.nextAudioRef && props.nextAudioRef.current) {
      // need to check if play worked
      // https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/
      let promise = props.nextAudioRef.current.play();

      if (promise !== undefined) {
        promise.then(() => {
          console.log('auto-playing next message')
          // Auto-play started
        }).catch(error => {
          console.error('auto-playing next message audio ref failed', error.toString())
          // Show a UI element to let the user manually start playback
        });
      }
    }
  }


  console.log('in message thisAudioRef', props.audioRef);
  console.log('SituationUserResponsePrompt render', props.messageSnapshot.data(), props.messageSnapshot.get('state'))

  return (
    <OnSnapshotController
      key={userRef.path}
      dbRef={userRef}
      >
      {data => data && data.snapshot &&
        <div className={classes.messageRow}>
          <img className={classes.profilePhoto} src={data.snapshot.get('photoURL')} />
          <div className={classes.messageCol}>
            <AudioPlayer
              audioRef={props.audioRef}
              onPlay={handlePlay}
              onPause={handlePause}
              onEnded={handleEnded}
              className={classes.audio}
              src={props.audioSrc}
              defaultExpanded={props.defaultExpanded}
              expandedContent={<React.Fragment>
                 <RedButton onClick={onClickDelete} className={classes.button} size="small">re-record</RedButton>
                 <Button onClick={onClickUserTranscript} className={classes.button} size="small">{showUserTranscript ? 'hide' : 'show'} what dokibo heard you say</Button>
                 {showUserTranscript &&
                  <div className={classes.transcript}>
                   {props.messageSnapshot.get('transcription') || 'no transcript'}
                  </div>}
                 <Button onClick={onClickNativeSpeakerTranscript} className={classes.button} size="small">{showNativeSpeakerTranscript ? 'hide' : 'show'} what a native speaker said</Button>
                 {showNativeSpeakerTranscript &&
                  <div className={classes.transcript}>
                   {props.promptSnapshot.get('text') || 'no transcript'}
                  </div>}
                 <Modal
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={deleteModalOpen}
                  onClose={handleDeleteModalClose}
                >
                  <div className={classes.modalStyle}>
                    <h2 id="simple-modal-title">Delete Message?</h2>
                    <p id="simple-modal-description">
                      Are you sure you want to delete this message? There's no going back.
                    </p>
                    <RedButton onClick={handleDeleteConfirmed}>delete</RedButton>
                    <Button onClick={handleDeleteModalClose}>keep</Button>
                  </div>
                </Modal>
              </React.Fragment>}
              />
          </div>
        </div>
        || null
      }
    </OnSnapshotController>
  );
});
