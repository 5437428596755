import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';

import resizeCanvasToDisplaySize from '../helpers/resizeCanvasToDisplaySize';
import rootMeanSquare from '../helpers/rootMeanSquare';
import {hexToRgb, rgbToHex, rgbToHsl, hslToRgb} from '../helpers/colorFunctions';

const styles = theme => ({
  canvas: {
    width: '100%',
    height: '100%',
  }
});

class AudioVisualization extends PureComponent {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.mostRecentAudioLevel = 0;
    this.mostRecentTime = new Date();
    this.targetAudioLevel = 0;
  }

  componentDidMount() {
    this.setupVisualization();
  }

  setupVisualization() {
    var context = this.canvasRef.current.getContext("2d");

    let draw = () => {
      if (this.canvasRef.current === null) {
        console.log('this.canvasRef.current === null, stopping draw');
        return;
      }

      resizeCanvasToDisplaySize(this.canvasRef.current);

      // get the canvas dimensions
      let width = this.canvasRef.current.width
      let height = this.canvasRef.current.height;

      // ask the browser to schedule a redraw before the next repaint
      requestAnimationFrame(draw);

      let currentTime = new Date();
      let diffSeconds = (currentTime - this.mostRecentTime) / 1000;

      this.targetAudioLevel = this.getNormalizedVolumeLevel();

      // over the course of the next quarter-second, get to the target value.
      this.mostRecentAudioLevel += Math.min(1.0, diffSeconds * 6) * (this.targetAudioLevel - this.mostRecentAudioLevel);
      this.mostRecentTime = currentTime;

      let maxRadius = Math.min(width, height)/2;
      let minRadius = 0.1 * maxRadius;
      let normalizedIntensity = Math.min(1.0, this.mostRecentAudioLevel*3);
      let radius = minRadius + normalizedIntensity * (maxRadius-minRadius);

      // clear the canvas
      context.fillStyle = '#ffffff';
      context.fillRect(0, 0, width, height);

      context.save()

      context.lineWidth =  5;

      let color = this.getGradientColor(
        this.props.theme.palette.primary.main,
        this.props.theme.palette.primary.dark,
        normalizedIntensity);
      context.strokeStyle = color;
      context.fillStyle = color;

      context.beginPath();
      context.arc(width/2, height/2, radius, 0, 2 * Math.PI);
      context.stroke();
      context.fill();

      context.restore()
    }

    draw();
  }

  getNormalizedVolumeLevel = () => {
    let loudness = rootMeanSquare(this.props.getAudioData());
    if (loudness > 0.1 && this.props.onLoudnessThresholdSurpassed) {
      this.props.onLoudnessThresholdSurpassed(loudness);
    }
    return loudness;
  }

  getGradientColor = (color1, color2, distance) => {
    let color1HSL = rgbToHsl(hexToRgb(color1));
    let color2HSL = rgbToHsl(hexToRgb(color2));

    let gradientColorHSL = {
      h: distance * (color2HSL.h - color1HSL.h) + color1HSL.h,
      s: distance * (color2HSL.s - color1HSL.s) + color1HSL.s,
      l: distance * (color2HSL.l - color1HSL.l) + color1HSL.l,
    }

    return rgbToHex(hslToRgb(gradientColorHSL));
  }

  render() {
    let classes = this.props.classes;

    return (
      <div>
        <canvas className={classes.canvas} ref={this.canvasRef} />
      </div>
    )
  }
}

AudioVisualization.propTypes = {
  getAudioData: PropTypes.func.isRequired,
  onLoudnessThresholdSurpassed: PropTypes.func,
};

export default withStyles(styles)(withTheme(AudioVisualization));
