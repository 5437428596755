export default function(text, speed, language, speaker, onend) {
  speed = speed || 1.0;
  language = language || 'zh-CN';
  speaker = speaker || 0;

  const speak = async (text) => {
    if (!speechSynthesis) { return }
    const message = new SpeechSynthesisUtterance(text)
    message.voice = await chooseVoice();
    message.rate = speed;
    message.addEventListener('end', onend);
    speechSynthesis.speak(message)
  }

  const getVoices = () => {
    return new Promise((resolve) => {
      let voices = speechSynthesis.getVoices()
      if (voices.length) {
        resolve(voices)
        return
      }
      speechSynthesis.onvoiceschanged = () => {
        voices = speechSynthesis.getVoices();
        resolve(voices)
      }
    })
  }

  const chooseVoice = async () => {
    const voices = (await getVoices()).filter((voice) => voice.lang == language)

    return new Promise((resolve) => {
      resolve(voices[speaker])
    })
  }

  speak(text)
}
