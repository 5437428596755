import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import firebase from 'firebase';
import queryString from 'query-string'

import RecordBar from '../views/RecordBar';
import Message from '../views/Message';
import OnSnapshotController from '../controllers/OnSnapshotController';
import AsyncStorageData from '../controllers/AsyncStorageData';
import WzRecorder from '../WzRecorder';
import DokiboHeader from '../views/DokiboHeader';
import SituationUserResponsePrompt from '../components/SituationUserResponsePrompt';
import SituationOtherSpeakerMessage from '../components/SituationOtherSpeakerMessage';
import removePunctuation from '../helpers/removePunctuation';


const styles = theme => ({
  container: {
    paddingTop: 'calc(56px)', // header is 56px
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  headerImage: {
    width: 'calc(100% + 16px)',
    display: 'block',
    marginLeft: '-8px',
    marginRight: '-8px',
  },
  conversation: {
    paddingBottom: '87px', // 79 for RecordBar + 8 for margin.
    overflow: 'scroll',
  },
  messagesContainer: {
  },
  vocabList: {
    display: 'flex',
    flexDirection: 'column',
  },
  vocabItem: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  nextButton: {
    marginTop: theme.spacing(1),
  }
});

class SituationDemoPage extends Component {
  state = {
    currentMessage: undefined,
  }

  constructor(props) {
    super(props);

    this.recorder = new WzRecorder({
      streaming: false,
      bufferSize: 8192,
      onRecordingStop: this.handleRecordingStop,
      onRecording: this.handleRecordingTick,
    });
  }

  componentDidMount() {
    this.situationRef().onSnapshot(snapshot => {
      console.log('situationSnapshot', snapshot);
      this.setState({
        snapshot: snapshot,
      });
    });

    this.situationUserRef().onSnapshot(snapshot => {
      console.log('situationUserSnapshot', snapshot);
      let currentMessage = snapshot.get('currentMessage');
      this.setState({
        currentMessage: currentMessage === undefined ? 0 : currentMessage,
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.situationId !== this.props.match.params.situationId) {
      console.warn('situationId shouldn\'t change. Use key=situationId to reset on situationId update')
    }
  }

  situationRef = () => {
    return firebase.firestore()
      .collection('situations').doc(this.props.match.params.situationId)
  }

  situationUserRef = () => {
    return firebase.firestore()
      .collection('users').doc(firebase.auth().currentUser.uid)
      .collection('situations').doc(this.props.match.params.situationId)
  }

  handleClickRecord = () => {
    this.recorder.toggleRecording();
    console.log('toggle recording');
  }

  waitingForUserToSpeak = () => {
    return this.state.waitingForUserToSpeak;
  }

  handleRecordingTick = (milliseconds, view, hasVoice) => {
    if (this.stopVisualizer === undefined && this.visualizerCanvasRef) {
      this.stopVisualizer = this.recorder.visualize(window.localStream, this.visualizerCanvasRef);
    }

    if (this.state.isMicrophoneOn !== true) {
      this.setState({
        isMicrophoneOn: true,
      })
    }
  }

  handleRecordingStop = (blob) => {
    console.log('handleRecordingStop with blob size ', blob.size, this.state.currentMessageId)

    this.setState({
      isMicrophoneOn: false,
    })

    this.situationUserRef().set({
      currentMessage: this.state.currentMessage + 1,
    }, {merge: true})

    var messageRef = firebase.firestore()
      .collection('users').doc(firebase.auth().currentUser.uid)
      .collection('situations').doc(this.props.match.params.situationId)
      .collection('messages').doc(this.state.currentMessageId);

    var storageRef = firebase.storage().ref()
      .child('users').child(firebase.auth().currentUser.uid)
      .child('situations').child(this.props.match.params.situationId)
      .child('messages').child(`${messageRef.id}-audio`);

    messageRef.set({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      audioState: 'uploading',
      userId: firebase.auth().currentUser.uid,
    }).then(snapshot => storageRef.put(blob))
    .then(snapshot => messageRef.set({
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      audioPath: storageRef.fullPath,
      audioState: 'uploaded',
    }, {merge: true}))
    .then(snapshot => {
      console.log(`finished uploading audio for message`, messageRef.id)
    })
    .catch(error => {
      console.log(error);

      messageRef.set({
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
        error: error.toString(),
      }, {merge: true})
    });
  }

  handleOtherSpeakerPlaybackEnded = (messageSnapshot) => {
    console.log('playback ended for message', messageSnapshot.id);

    // start at 1 indexing for the snapshot, start at 0 for the currentMessage
    if (messageSnapshot.get('messageNumber') - 1 === this.state.currentMessage) {
      this.situationUserRef().set({
        currentMessage: this.state.currentMessage + 1,
      }, {merge: true})
    }
  }

  setVisualizerCanvasRef = (element) => {
    console.log('setVisualizerCanvasRef', this.stopVisualizer, window.localStream, element);
    this.stopVisualizer && this.stopVisualizer();

    if (element !== null && window.localStream !== undefined) {
      this.stopVisualizer = this.recorder.visualize(window.localStream, element);
    }

    this.visualizerCanvasRef = element;
  }

  renderOtherUserMessage = (data) => {
    console.log('renderOtherUserMessage', data);

    if (!(data.props.message && data.snapshot)) {
      return null;
    }

    if (data.snapshot.get('audioPath')) {
      return (
        <AsyncStorageData
          key={data.snapshot.get('audioPath')}
          storageRef={firebase.storage().ref(data.snapshot.get('audioPath'))}
          >
          {storageData => storageData &&
            <SituationUserResponsePrompt
              defaultExpanded={false}
              audioSrc={storageData.downloadUrl}
              onPlaybackEnded={this.handleOtherSpeakerPlaybackEnded}
              promptSnapshot={data.props.message}
              messageSnapshot={data.snapshot} />
            || null
          }
        </AsyncStorageData>
      )
    } else if (data.snapshot.data() !== undefined) {
      return (
        <SituationUserResponsePrompt
          defaultExpanded={false}
          promptSnapshot={data.props.message}
          messageSnapshot={data.snapshot} />
      )
    } else {
      return null;
    }
  }

  renderMessages = (snapshot) => {
    let classes = this.props.classes;
    var elements = [];
    var i=0;

    if (this.state.currentMessage === undefined) {
      return null;
    }

    snapshot.forEach(message => {
      if (i > this.state.currentMessage) {
        i += 1;
        return;
      }

      console.log('messageForEach', message.data())

      if (message.get('speaker') !== '学生') {
        if (i === this.state.currentMessage) {
          // yes causes rerender
          if (this.state.currentMessageId !== message.id) {
            this.setState({
              currentMessageId: message.id,
              waitingForUserToSpeak: false,
            })
          }
        }
        console.log('audioPath', message.get('audioPath'))

        if (message.get('audioPath') !== undefined && message.get('audioPath') !== null) {

          elements.push(
            <AsyncStorageData
              key={message.id}
              storageRef={firebase.storage().ref(message.get('audioPath'))}
              message={message}
              i={i}
              >
              {data => data &&
                <SituationOtherSpeakerMessage
                  defaultExpanded={data.i-1 <= this.state.currentMessage}
                  snapshot={data.message}
                  audioSrc={data.downloadUrl}
                  autoplay={false}
                  onPlaybackEnded={this.handleOtherSpeakerPlaybackEnded}
                  />
              }
            </AsyncStorageData>
          );
        } else {
          // no audio recorded yet
          elements.push(
            <SituationOtherSpeakerMessage
              defaultExpanded={i-1 <= this.state.currentMessage}
              snapshot={message}
              autoplay={false}
              onPlaybackEnded={this.handleOtherSpeakerPlaybackEnded}
              />
          )
        }
      } else {
        if (i === this.state.currentMessage) {
          // yes causes rerender
          if (this.state.currentMessageId !== message.id) {
            this.setState({
              currentMessageId: message.id,
              waitingForUserToSpeak: true,
            })
          }

          if (removePunctuation(message.get('text')) === this.state.transcription) {
            this.setState({
              transcription: '',
            })
            this.situationUserRef().set({
              currentMessage: this.state.currentMessage + 1,
            }, {merge: true})
          };

          elements.push(
            <div>
              <Typography variant='subtitle2'>Your turn</Typography>
              <Typography variant='body2' element='p'>
                {message.get('prompt')}
              </Typography>
            </div>
          )
        } else {
          let userMessageRef = firebase.firestore()
            .collection('users').doc(firebase.auth().currentUser.uid)
            .collection('situations').doc(this.props.match.params.situationId)
            .collection('messages').doc(message.id);

          console.log('and message is', message);

          elements.push(
            <OnSnapshotController
              key={userMessageRef.path}
              dbRef={userMessageRef}
              message={message}
              >
              {data => data &&
                this.renderOtherUserMessage(data)
                || null
              }
            </OnSnapshotController>
          )
        }
      }

      i += 1;
    })

    if (this.state.currentMessage >= i) {
      //let nextUrl = queryString.parse(this.props.location.search).next;
      let nextUrl = this.state.snapshot.get('nextUrl');

      elements.push(
        <React.Fragment>
          <Typography variant="h5">{this.state.snapshot.get('doneMessage')}</Typography>
          <Button
            className={classes.nextButton}
            variant='contained'
            color='primary'
            href={nextUrl}>
            Continue
          </Button>
        </React.Fragment>
      )
    }

    return elements;
  }

  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.conversation}>
        <DokiboHeader
          title='Dokibo | Conversation'
          breadcrumbs={[
            {title: 'Dokibo', href: `/`},
            {title: this.state.snapshot && this.state.snapshot.get('title') || 'Situation'}
          ]}

        />
        <div className={classes.container}>
          {this.state.snapshot &&
            <React.Fragment>
              <img className={classes.headerImage} src={this.state.snapshot.get('headerImageUri')} />
              <Typography variant="h5">{this.state.snapshot.get('title')}</Typography>
              <Typography variant="body2" component="p">{this.state.snapshot.get('introduction')}</Typography>
              <Typography variant="h6">Classroom</Typography>
              <Typography variant="body2" component="p">Review the following vocab:</Typography>
              <div className={classes.vocabList}>
                {this.state.snapshot.get('vocab').map(item =>
                  <div className={classes.vocabItem} key={item.chinese}>
                    <Typography variant="h6" component="span">{item.chinese}</Typography>
                    <Typography variant="body2" component="span"> {item.pinyin}</Typography>
                    <Typography variant="caption" component="div">{item.definition}{item.partOfSpeech && ` (${item.partOfSpeech})`}</Typography>
                  </div>
                )}
              </div>
            </React.Fragment>
          }

          <Typography variant="h6">Conversation</Typography>
          <Typography variant="body2" element="p">After you've reviewed the vocab, tap the play button to get started.</Typography>

          <OnSnapshotController
            key={this.situationRef().collection('messages').path}
            dbRef={this.situationRef().collection('messages').orderBy('messageNumber', 'asc')}
            >
            {data =>
              <div className={classes.messagesContainer}>
                {data && data.snapshot &&
                  this.renderMessages(data.snapshot)
                }
              </div>
            }
          </OnSnapshotController>
        </div>

        <RecordBar
          recorder={this.recorder}
          disabled={!this.waitingForUserToSpeak()}
          onClickRecord={this.handleClickRecord}
          isRecording={this.state.isMicrophoneOn === true}
           />
      </div>
    );
  }
}


SituationDemoPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(SituationDemoPage);
