import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import Fab from '@material-ui/core/Fab';
import MicIcon from '@material-ui/icons/Mic';
import { red } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';

import DokiboHeader from '../views/DokiboHeader';
import AudioPlayer from '../components/AudioPlayer';

import firebase from 'firebase';

const styles = theme => ({
  container: {
    paddingTop: 'calc(56px)', // header is 56px
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1200px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  introDiv: {
    background: `
      linear-gradient(
        180deg,
        rgba(255,255,255,1) 0%,
        rgba(0,0,0,0) 40%,
        rgba(0,0,0,0) 65%,
        rgba(255,255,255,1) 100%
      ),
      linear-gradient(
        90deg,
        rgba(255,255,255,1) 0%,
        rgba(0,0,0,0) 40%,
        rgba(0,0,0,0) 65%,
        rgba(255,255,255,1) 100%
      ),
      url(${process.env.PUBLIC_URL + '/demo_rough_sketch_sepia.png'})
      `,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  introDivSpacer: {
    height: '250px',
  },
  introDivEnd: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',

  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    maxWidth: '640px',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
      alignSelf: 'center',
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.up('xl')]: {
      textAlign: 'center',
      alignSelf: 'center',
      marginTop: theme.spacing(25),
    },
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    maxWidth: '640px',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
      alignSelf: 'center',
    },
  },
  primaryAction: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      width: 'fit-content',
      textAlign: 'center',
      alignSelf: 'center',
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.up('xl')]: {
      width: 'fit-content',
      textAlign: 'center',
      alignSelf: 'center',
      marginBottom: theme.spacing(25),
    },
  },
  section: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  sectionInverted: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  characterPortrait: {
    width: '128px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  text: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  valuePropHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  betterSpeakingPracticeBullets: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(5),
  },
  betterPracticeBullet: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  betterPracticeBulletCheckbox: {
    width: '36px',
    height: '36px',
    flexShrink: '0',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #666666',
    marginRight: theme.spacing(1),
  },
  betterPracticeBulletCheckboxIcon: {
    margin: 'auto auto',
  },
  betterPracticeBulletText: {

  },
  ctaSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.getContrastText(theme.palette.secondary.dark),
  },
});

class HomePage extends Component {
  state = {

  }

  render() {
    const classes = this.props.classes;

    let betterSpeakingPracticeBullets = [
      'Free speaking practice',
      'All you need is your phone',
      'No need to schedule sessions ahead of time',
      'Review key vocab and grammar just before it’s needed',
      'Practice real-world scenarios like ordering boba tea and having a conversation on the train',
      'Purchase dedicated time with teachers to improve your skills even faster',
    ]

    return (
      <div>
        <DokiboHeader
          title='Dokibo | Home'
          breadcrumbs={[
            {title: 'Dokibo', href: `/`}
          ]}
        />
        <div className={classes.container}>
          <div className={classes.introDiv}>
            <Typography className={classes.title} color='inherit' variant='h5' element='h5'>You’ve landed in Běijīng and your adventure awaits...</Typography>
            <div className={classes.introDivSpacer} />
            <Typography className={classes.title} variant='h5' element='h5'>Do you have the speaking skills to survive?</Typography>
          </div>
          <div className={classes.introDivEnd}>
            <Typography className={classes.subtitle} variant='body2'>
              Dokibo is an immersive chinese world. Explore winding back alleys,
              distant remote villages, sky-high mountains, and more. Experience
              daily life by talking to shopkeepers, office workers, and if
              you’re lucky, maybe even a celebrity or a talking panda.
            </Typography>
            <Typography className={classes.subtitle} variant='body2'>
            Listen to their stories and
            tell your own as you become a confident mandarin speaker!
            </Typography>
            {firebase.auth().currentUser ?
              <Button className={classes.primaryAction}
                      variant='contained'
                      color='primary'
                      href="/place/beijing-airport">Get Started</Button>
              :
              <Button className={classes.primaryAction}
                      variant='contained'
                      color='primary'
                      href="/signin?next=/place/beijing-airport">Get Started</Button>
            }
          </div>
          <div className={classes.sectionInverted}>
            <Typography className={classes.valuePropHeader} variant='h6' element='h5'>New Storyline: The Street Vendor’s Travels</Typography>
            <img className={classes.characterPortrait} src={process.env.PUBLIC_URL + '/street-vendor.png'} />
            <AudioPlayer
              src="https://firebasestorage.googleapis.com/v0/b/dokibo-speak-prod.appspot.com/o/recordings%2Fchao-shou-vendor.m4a?alt=media&token=018de873-29be-43a3-ad1c-8f33d58baa3e"
              defaultExpanded={true}
              expandedContent={<React.Fragment>
                <div className={classes.transcript}>
                  <div>
                    Transcript: 抄手，抄手，好吃抄手！
                  </div>
                  <div>
                    Chāoshǒu, chāoshǒu, hào chī chāoshǒu!
                  </div>
                </div>
              </React.Fragment>}
              />
            <Typography className={classes.text} variant='body2'>
              The street vendor was born in Chengdu, but during the cultural revolution grew up in a rural Sichuanese town. From his earliest days he was fascinated by food and the process of making it from raw materials. He learned to grow wheat and rice, and mill them into flour to make noodles, dumplings, and more. He learned to brew soy sauce and become known for creating an amazing bowl of 抄手 (chāoshǒu).
            </Typography>
            <Typography className={classes.text} variant='body2'>
              Now something is wrong, but the street vendor isn't telling anyone yet. Perhaps if there was someone he could trust...
            </Typography>

          </div>
          <div className={classes.section}>
            <Typography className={classes.valuePropHeader} variant='h6' element='h5'>Dokibo is better practice for speaking</Typography>
            <div className={classes.betterSpeakingPracticeBullets}>
              {betterSpeakingPracticeBullets.map(bulletText =>
                <div key={bulletText} className={classes.betterPracticeBullet}>
                  <div className={classes.betterPracticeBulletCheckbox}>
                    <CheckIcon className={classes.betterPracticeBulletCheckboxIcon} />
                  </div>
                  <Typography variant='body2' className={classes.betterPracticeBulletText}>
                    {bulletText}
                  </Typography>
                </div>
              )}
            </div>
          </div>
          <div className={classes.ctaSection}>
            <Typography className={classes.subtitle} variant='h5'>Say hello to better speaking skills</Typography>
            {firebase.auth().currentUser ?
              <Button variant='contained'
                      color='primary'
                      href="/warmup">Get Started</Button>
              :
              <Button variant='contained'
                      color='primary'
                      href="/signin?next=/warmup">Get Started</Button>
            }
          </div>
        </div>
      </div>
    );
  }
}


HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomePage);
